import axios, { AxiosInstance } from "axios";
import * as constants from "../constants";

class TeamVibesApiService {
  static AuthenticatedApi = axios.create({
    baseURL: constants.Api.baseUri,
    // will inject auth header on-demand later as needed.
    headers: {
      "Content-Type": "application/json",
    },
  });

  static GetTenantViewType(token) {
    return this.AuthenticatedApi.get("/TeamVibeConfig/CheckTenantViewType", {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.CheckTenantViewType() - An error has occurred calling the web api: " + error);
        return null;
    });
  }
  
  static GetTeamVibesQuestions(token) {
    return this.AuthenticatedApi.get("/TeamVibeConfig/GetTeamVibesQuestions", {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.GetTeamVibesQuestions() - An error has occurred calling the web api: " + error);
        return null;
    });
  }

  static AddorUpdateQuestion(Question, token) {
    return this.AuthenticatedApi.post("/TeamVibeConfig/AddorUpdateQuestion/", Question, {
      params: {
        Question: Question,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.AddorUpdateQuestion() - An error has occurred calling the web api: " + error);
        return null;
    });
  }

  static DeleteTeamVibesQuestions(QuestionId, token) {
    return this.AuthenticatedApi.delete("/TeamVibeConfig/DeleteTeamVibesQuestions/" + QuestionId, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.DeleteTeamVibesQuestions() - An error has occurred calling the web api: " + error);
        return null;
    });
  }

  static GetCategoriesAndPriorities(token) {
    return this.AuthenticatedApi.get("/TeamVibeConfig/GetCategoriesAndPriorities", {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.GetCategoriesAndPriorities() - An error has occurred calling the web api: " + error);
        return null;
    });
  }

  static GetQuestionsForWellBeingTemplate(priorityId, categoryIdList, token) {
    return this.AuthenticatedApi.get("/TeamVibeConfig/GetQuestionsForWellBeingTemplate",  {
      params: {
        priorityId: priorityId,
        categoryIdList: categoryIdList,
      },
      headers: {
            Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.GetQuestionsForWellBeingTemplate() - An error has occurred calling the web api: " + error);
        return null;
    });
  }

    static AddOrUpdateWellBeingTemplate(template, token) {
        return this.AuthenticatedApi.post("/TeamVibeConfig/AddOrUpdateWellBeingTemplate/", template, {
            params: {
                wellBeingFormTemplate: template,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.AddOrUpdateWellBeingTemplate() - An error has occurred calling the web api: " + error);
        return null;
    });
  }
  
  static UpdateWellBeingRunState(template, token) {
    return this.AuthenticatedApi.post("/TeamVibeConfig/UpdateWellBeingRunState/", template, {
        params: {
            wellBeingFormTemplate: template,
  },
  headers: {
    Authorization: "Bearer " + token,
  },
})
.then((response) => {
    return response.data;
})
.catch((error) => {
    console.log("TeamVibesApiService.UpdateWellBeingRunState() - An error has occurred calling the web api: " + error);
    return null;
});
}
    static GetTeamVibeHome(token) {
      return this.AuthenticatedApi.get("/TeamVibeConfig/GetTeamVibeHome",  {
      headers: {
            Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("TeamVibesApiService.GetTeamVibeHome() - An error has occurred calling the web api: " + error);
        return null;
    });
  }
}

export default TeamVibesApiService;