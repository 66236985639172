import React from "react";
import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Form,
  Checkbox,
  Button,
  Text,
  Divider,
  Dropdown,
  Avatar,
  Grid as FluentGrid,
  Tooltip,
  Input,
  Loader,
  FormInput
} from "@fluentui/react-northstar";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import {
  TeamCreateIcon,
  SearchIcon,
  EditIcon,
  PresenceAvailableIcon,
  ExclamationTriangleIcon,
  ShiftActivityIcon
} from "@fluentui/react-icons-northstar";
import "../Dashboard/dashboard.scss";
import "./config.scss";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import useMediaQuery from "../../Shared/CustomHooks/useMediaQuery";
import WindowContext from "../../Shared/Context/Context";
import CenteredHeader from "../../Shared/Header/Header";
import Impersonation from "./Impersonation";


const EditCallQueue = (props) => {
  const [loading, setLoading] = useState(false);
  const [targetReturnTime, setTargetReturnTime] = useState(null);
  const [targetAnswerTime, setTargetAnswerTime] = useState(null);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);


  useEffect(() => {
    setTargetReturnTime(props.selectedCallQueueDetail.targetReturnTime);
    setTargetAnswerTime(props.selectedCallQueueDetail.targetAnswerTime);
  }, [props]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  }

  const submitForm = () => {
    if (targetReturnTime === null || targetAnswerTime === null) {
      return;
    }
    setLoading(true);
    var data = {
      callQueueGUID: props.selectedCallQueueDetail.callQueueGUID,
      targetReturnTime: parseInt(targetReturnTime),
      targetAnswerTime: parseInt(targetAnswerTime)
    }
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.UpdateCallQueue(data, token).then((response) => {
        if (response !== undefined && response !== "") {
          props.handleEditChange(props.selectedCallQueueDetail.callQueueGUID, targetReturnTime, targetAnswerTime);
        }
        setLoading(false);
      });
    });
  }

  return (
    <WindowContext.Consumer>
      {(context) =>
        loading ? <Loader /> :
          <Box>
            <Form className="" onSubmit={submitForm} styles={{ marginTop: "20px", fontSize: '0.9rem' }}>
              <FluentGrid className="whRow" columns={2} styles={{ width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns: '200px calc(100% - 210px)', marginBottom: '15px !important' }}>
                <Text content="Target Return Time (Seconds): " />
                <FormInput
                  style={{ width: "100%" }}
                  type={"number"}
                  value={targetReturnTime}
                  onChange={(event) => setTargetReturnTime(event.target.value)}
                  onKeyDown={handleKeyDown}
                  fluid={true}
                  required
                />
              </FluentGrid>
              <FluentGrid className="whRow" columns={2} styles={{ width: '100%', alignItems: 'center', gridColumnGap: '8px', gridTemplateColumns: '200px calc(100% - 210px)', marginBottom: '15px !important' }}>
                <Text content="Target Answer Time (Seconds): " />
                <FormInput
                  style={{ width: "100%" }}
                  type={"number"}
                  value={targetAnswerTime}
                  onChange={(event) => setTargetAnswerTime(event.target.value)}
                  onKeyDown={handleKeyDown}
                  fluid={true}
                  required
                />
              </FluentGrid>

              <Flex space="between" styles={{ paddingTop: "50px" }}>
                <Box />
                <Flex gap="gap.small" className="align-items-center">
                  {isDemo && <div className="col text-right px-0">Exploring app with sample data.</div>}
                  <Button
                    disabled={targetReturnTime === "" || targetAnswerTime === "" || isDemo === true}
                    content="Save"
                    type="submit"
                    primary
                  />
                </Flex>
              </Flex>
            </Form>
          </Box>
      }
    </WindowContext.Consumer>
  );
}


const CellWithAction = (props) => {
  const callQueue = props.dataItem;
  return (
    <td style={{ textAlign: "center" }}>
      <Button
        onClick={() => props.onOpen(callQueue)}
        icon={<EditIcon />}
        iconOnly
        title="Edit"
        size="small"
        primary
        disabled={props.dataItem.disabled}
      />
    </td>
  );
};

const DetailComponent = (props) => {
  const callQueue = props.dataItem;
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <tr>
          <td>Target Return Time (Minutes):</td>
          <td class="text-right">
            <Text content={callQueue.targetReturnTime} />
          </td>
        </tr>
        <tr>
          <td>Target Answer Time (Minutes):</td>
          <td class="text-right">
            <Text content={callQueue.targetAnswerTime} />
          </td>
        </tr>
      </table>
    </section>
  );
};


const CallQueueConfig = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [callQueueData, setCallQueueData] = useState(null);
  const [selectedCallQueueDetail, setSelectedCallQueueDetail] = useState(null);
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState([]);

  useEffect(() => {
    setSearchInput("");
    loadCallQueueData();
  }, []);

  const onOpen = (callQueue) => {
    setSelectedCallQueueDetail(callQueue);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedCallQueueDetail(null);
  };

  const expandChange = (event) => {
    let newData = callQueueData.map((item) => {
      if (item.callQueueName === event.dataItem.callQueueName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setCallQueueData(newData);
  };

    const loadCallQueueData = () => {
    setCallQueueData(null);
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetCallQueues(token).then((response) => {
        if (response != undefined && response != "") {
          var tempCallQueues = [];
          response.forEach((item) => {
            var data = {
              callQueueName: item.callQueueName,
              id: item.callQueueGUID,
              callQueueGUID: item.callQueueGUID,
              targetReturnTime: item.targetReturnTime,
              targetAnswerTime: item.targetAnswerTime,
              disabled: !item.isActive,
              style: !item.isActive ? { opacity: .5 } : { opacity: 1 },
            };
            tempCallQueues.push(data);
          });
          setCallQueueData(tempCallQueues);
        } else {
          setCallQueueData([]);
        }
      });
    });
  };

  const handleEditChange = (callQueueGUID, targetReturnTime, targetAnswerTime) => {
    var tempData = [...callQueueData];
    var indexId = tempData.findIndex((x) => x.callQueueGUID === callQueueGUID);
    tempData[indexId].targetReturnTime = targetReturnTime;
    tempData[indexId].targetAnswerTime = targetAnswerTime;
    setCallQueueData(tempData);
  }

  const CustomCellWithAction = (props) => (
    <CellWithAction
      {...props}
      onOpen={(callQueue) => { onOpen(callQueue); }}
    />
  );

  return (
    <WindowContext.Consumer>
      {
        context => (
          <>
            <Flex gap="gap.small" padding="padding.medium" column fill>
              <Flex.Item>
                <Input
                  fluid
                  icon={<SearchIcon />}
                  clearable
                  value={searchInput}
                  placeholder="Search..."
                  onChange={(e, { name, value }) => {
                    setSearchInput(value);
                  }}
                />
              </Flex.Item>
              <Flex.Item>
                <Grid
                  style={{ maxHeight: "calc(100vh - 115px)" }}
                  detail={context.mediaCategory.sm ? DetailComponent : null}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  data={
                    callQueueData != null
                      ? orderBy(callQueueData.filter((callQueue) =>
                        callQueue.callQueueName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ), sort)
                      : null
                  }
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                >
                  <GridNoRecords>
                    {callQueueData != null ? " " : <Loader />}
                  </GridNoRecords>
                  <Column
                    width={context.mediaCategory.sm ? "calc(100% - 150px)" : "calc(100% - 520px)"}
                    title="Call Queue Name"
                    field="callQueueName"
                  />
                  {!context.mediaCategory.sm && (
                    <Column
                      width="170px"
                      className="pl-4 text-center"
                      title="Target Return Time (Seconds)"
                      field="targetReturnTime"
                      headerCell={CenteredHeader}
                    />
                  )}
                  {!context.mediaCategory.sm && (
                    <Column
                      width="170px"
                      className="pl-4 text-center"
                      title="Target Answer Time (Seconds)"
                      field="targetAnswerTime"
                      headerCell={CenteredHeader}
                    />
                  )}
                  <Column
                    width={context.mediaCategory.sm ? "60px" : "100px"}
                    title="Actions"
                    cell={CustomCellWithAction}
                    headerCell={CenteredHeader}
                  />
                </Grid>
              </Flex.Item>
                <Text content={callQueueData !== null ? "Call queues will become active on receiving a call." : ""} className="px-2 text-secondary" ></Text>
            </Flex>
            {open && (
              <Dialog
                title={<Text size="large">Edit {selectedCallQueueDetail.callQueueName}</Text>}
                onClose={() => onCancel()}
                style={{ overflow: "scroll" }}
                width={context.mediaCategory.sm ? null : 400}
                height={context.mediaCategory.sm ? null : 300}
              >
                <EditCallQueue
                  selectedCallQueueDetail={selectedCallQueueDetail}
                  handleEditChange={handleEditChange}
                />
              </Dialog>
            )}
          </>
        )
      }
    </WindowContext.Consumer>
  );
};

export default CallQueueConfig;
