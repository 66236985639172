import { useState, useEffect } from "react";
import "./dashboard.scss";
import {
  _cardCategoryType,
  _cardCollaborationType,
} from "./allwidgets";

import {
  Flex,
  Input,
  Button,
  Segment,
  List,
  Text,
  Loader,
  Divider,
} from "@fluentui/react-northstar";
import { AddIcon, SearchIcon, CallDialpadIcon, ThumbtackIcon, ThumbtackSlashIcon } from "@fluentui/react-icons-northstar";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import SingleCardSettings from "../../filters/card/SingleCardSettings/SingleCardSettings";
import FiltersApiService from "../../../services/FiltersApiService";
import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services/auth-helper";
import CenteredHeader from "../../Shared/Header/Header";
import QuickSetupCard from "./QuickSetupCard";
import CardCategories from "./CardCategories";
import { WidgetPreview } from "../../cards/Card/Card";
import WindowContext from "../../Shared/Context/Context";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useSetState } from "react-use";
const CellWithPinIcon = (props) => {
  let data = props.dataItem;
  return (
    <td className={data.isPinned == 1 ? "a365-is-pinned-list" : "a365-not-pinned-list"}>
      <Button style={{ minWidth: "24px" }}
        onClick={() => props.handlePinnedCards(data)}
        icon={data.isPinned == 1 ? <ThumbtackIcon rotate={-45} /> : <ThumbtackIcon outline />}
        size="small"
        secondary
      />
    </td>);
};
const CellWithVisualizationIcon = (props) => {
  const data = props.dataItem;
  return (
    <td className={data.isPinned == 1 ? "a365-is-pinned-list" : "a365-not-pinned-list"}>
      <div className="widget_visualization_container">
        {data.visualisationID === 5 && (
          <i title="Summary" className={`analytics-summary`} />
        )}
        {data.visualisationID === 3 && (
          <i title="Bar Chart" className={`analytics-barchart`} />
        )}
        {data.visualisationID === 7 && (
          <i title="Line Chart" className={`analytics-linechart`} />
        )}
        {data.visualisationID === 1 && (
          <i title="Pie Chart" className={`analytics-piechart`} />
        )}
        {data.visualisationID === 6 && (
          <i title="List" className={`analytics-list`} />
        )}
        {data.visualisationID === 4 && (
          <i title="Table" className={`analytics-table`} />
        )}
        {data.visualisationID === 2 && (
          <i
            title="General Analytics"
            className={`analytics-generalanalytics`}
          />
        )}
      </div>
    </td>
  );
};
const CellWithCategoryIcon = (props) => {
  const data = props.dataItem;
  return (
    <div className="widget_target_container">
      {
        data.isProductivity === 1 && (
          <i title="People Engagement" className={"analytics-productivity"} />
        )
        // data.isProductivity === 1 && <Avatar title="Productivity" size="smallest" image="/images/productivity.svg"  />
      }
      {data.isCustomerExperience === 1 && (
        <i
          title="Customer Engagement"
          className={"analytics-customerExperience"}
        />
      )}
      {data.isWellness === 1 && (
        <i title="Wellbeing" className={"analytics-wellbeing"} />
      )}
      {data.isTeamsCalling === 1 && (
        <i title="External Engagement" className={"analytics-headphones"} />
      )}
    </div>
  );
};
const CellWithViewIcon = (props) => {
  const data = props.dataItem;
  return (
    <Button
      // title="Preview"

      onClick={() => props.handlePreviewOpr(data)}
      className="widget_category_container"
      text
      iconOnly
      content={
        <div className="widget_visualization_container">
          {data.visualisationID === 5 && (
            <i title="Click to preview" className={`analytics-summary`} />
          )}
          {data.visualisationID === 3 && (
            <i title="Click to preview" className={`analytics-barchart`} />
          )}
          {data.visualisationID === 7 && (
            <i title="Click to preview" className={`analytics-linechart`} />
          )}
          {data.visualisationID === 1 && (
            <i title="Click to preview" className={`analytics-piechart`} />
          )}
          {data.visualisationID === 6 && (
            <i title="Click to preview" className={`analytics-list`} />
          )}
          {data.visualisationID === 4 && (
            <i title="Click to preview" className={`analytics-table`} />
          )}
          {data.visualisationID === 2 && (
            <i
              title="Click to preview"
              className={`analytics-generalanalytics`}
            />
          )}
        </div>
      }
    // content={
    //   <>
    //     {data.categoryTypeID === 1 && (
    //       <i title="Click to view" className={"analytics-summary"} />
    //     )}
    //     {data.categoryTypeID === 2 && (
    //       <i title="Click to view" className={"analytics-activity2"} />
    //     )}
    //     {data.categoryTypeID === 3 && (
    //       <i title="Click to view" className={"analytics-trends"} />
    //     )}
    //     {data.categoryTypeID === 4 && (
    //       <i title="Click to view" className={"analytics-comparison2"} />
    //     )}
    //     {data.categoryTypeID === 5 && (
    //       <i title="Click to view" className={"analytics-insights"} />
    //     )}
    //     {data.categoryTypeID === 6 && (
    //       <i title="Click to view" className={"analytics-list"} />
    //     )}
    //   </>
    // }
    />
  );
};
const CellWithTypeIcon = (props) => {
  const data = props.dataItem;
  return <Text content={_cardCollaborationType[data.collaborationTypeID]} />;
};
const CellWithAddIcon = (props) => {
  const data = props.dataItem;
  return (
    <td style={{ textAlign: "center" }} className={data.isPinned == 1 ? "a365-is-pinned-list" : "a365-not-pinned-list"} id={data["widgetName"] === "Team Comparison" ? "introCardAdd" : null}>
      <Button
        onClick={() => props.addCard({ ...data, cardID: -1 })}
        icon={<AddIcon />}
        content="Add"
        size="small"
        primary
      />
    </td>
  );
};

const DetailComponent = (props) => {
  const dataItem = props.dataItem;
  return (
    <section style={{ paddingRight: "32px" }}>
      <table>
        <thead>
          <tr>
            <td>Engagement:</td>
            <td className="text-right">
              <CellWithCategoryIcon {...props} />
            </td>
          </tr>
          <tr>
            <td>Preview:</td>
            <td className="text-right">
              <CellWithViewIcon {...props} />
            </td>
          </tr>
          <tr>
            <td>Type:</td>
            <td className="text-right">
              <CellWithTypeIcon {...props} />
            </td>
          </tr>
        </thead>
      </table>
    </section>
  );
};

const CardsList = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [cardlist, setCardlist] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewObj, setPreviewObj] = useState(null);
  const [sort, setSort] = useState([]);
  useEffect(() => {
    setSearchInput("");
    setCardlist(props.cards);
    console.log(props.cards);
  }, [props.cards]);

  const expandChange = (event) => {
    let newData = cardlist.map((item) => {
      if (item.widgetName === event.dataItem.widgetName) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setCardlist(newData);
  };
  const handlePinnedCards = (data) => {
    AuthHelper.getAccessToken(function (token) {
      FiltersApiService.UpdatePinnedCard(data.cardWidgetID, token).then(
        (response) => {
          if (response) {

          }
        }
      );
    });
    let temp = [...cardlist];
    temp.sort((a, b) => {
      if (a.isPinned && b.isPinned) {
        if (a.cardWidgetID == data.cardWidgetID) {
          //if (!flag) { a.isPinned = (a.isPinned == 1) ? 0 : 1 };
          //flag = 1;
          return 1;
        } else if (b.cardWidgetID == data.cardWidgetID) {
          return -1;
        } else {
          return a.dateInserted - b.dateInserted;
        }
      } else {
        if (a.isPinned) {
          return -1;
        } else if (b.isPinned) {
          return 1;
        } else if (a.cardWidgetID == data.cardWidgetID) {
          return -1;
        } else if (b.cardWidgetID == data.cardWidgetID) {
          return 1;
        } else {
          return 1;
        }
      }
    })

    var _pIndex = temp.findIndex(e => e.cardWidgetID === data.cardWidgetID);
    if (_pIndex !== -1) {
      temp[_pIndex].isPinned = (temp[_pIndex].isPinned == 1) ? 0 : 1;
    }

    setCardlist(temp);
  }
  const PinCardCell = (props) => (
    <CellWithPinIcon {...props} handlePinnedCards={handlePinnedCards} cardList={cardlist} />
  );
  const addCard = (cardData) => {
    props.onOpen(cardData);
  };

  const AddCardCell = (props) => (
    <CellWithAddIcon {...props} addCard={addCard} />
  );
  const CategoryCell = (props) => {
    return (
      <td >
        <CellWithCategoryIcon {...props} />
      </td>
    );
  };
  const ViewCell = (props) => {
    return (
      <td>
        <CellWithViewIcon
          {...props}
          handlePreviewOpr={(_prewiewObj) => {
            setPreviewObj(_prewiewObj);
            setOpenPreview(true);
          }
          }
        />
      </td>
    );
  };
  const TypeCell = (props) => {
    return (
      <td>
        <CellWithTypeIcon {...props} />
      </td>
    );
  };
  const DetailComponentTemplate = (props) => (
    <DetailComponent
      {...props}
      handlePreviewOpr={(_prewiewObj) => {
        setPreviewObj(_prewiewObj);
        setOpenPreview(true);
      }}
    />
  );
  const VisualizationHeaderCell = (props) => (
    <a className="k-link text-center" title="Visualization">
      <span className="visualisation-span">
        <i className="analytics-summary" />
      </span>
    </a>
  );
  const PinHeaderCell = () => (
    <a className="k-link text-center" title="Pin/Unpin Cards">
      <span className="visualisation-span">
        <ThumbtackIcon />
      </span>
    </a>
  );
  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setSort(event.sort);
      return;
    }
    var sortOrder = event.sort[0].dir;
    var columnName = event.sort[0].field;
    if (columnName === "widgetName") {
      let sortArray = [{ field: 'widgetName', dir: sortOrder }];
      setSort(sortArray);
    }
    else
      if (columnName === "collaborationTypeID") {
        let sortArray = [{ field: 'collaborationTypeID', dir: sortOrder }];
        setSort(sortArray);
      }
      else
        if (columnName === "visualisationID") {
          let sortArray = [{ field: 'visualisationID', dir: sortOrder }];
          setSort(sortArray);
        }
  };
  return (
    <WindowContext.Consumer>
      {(context) => (
        <>
          <Flex gap="gap.small" padding="padding.medium" column fill>
            <Flex gap="gap.small" vAlign="center">
              <Input
                fluid
                icon={<SearchIcon />}
                clearable
                placeholder="Search..."
                value={searchInput}
                onChange={(e, { name, value }) => {
                  setSearchInput(value);
                }}
              />
              <Flex.Item push>
                <QuickSetupCard
                  // demo={props.demo}
                  //setupOpen={setupOpen}
                  // cards={cards}
                  dashboardId={props.dashboardId}
                  userFiltersData={props.userFiltersData}
                  handleOpen={() => props.handleOpen}
                  addcardsList={props.addcardsList}
                />
              </Flex.Item>
            </Flex>

            <Flex.Item>
              <Grid
                className="border-bottom"
                style={{ height: `${context.mediaCategory.sm ? "calc(100vh - 284px)" : "calc(100% - 38px)"}` }}
                detail={
                  context.mediaCategory.sm ? DetailComponentTemplate : null
                }

                expandField="expanded"
                onExpandChange={expandChange}
                data={
                  cardlist != null
                    ? [...orderBy(cardlist.filter((card) =>
                      card.widgetName
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()) && card.isPinned === 1
                    ), sort),
                    ...orderBy(cardlist.filter((card) =>
                      card.widgetName
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()) && card.isPinned === 0
                    ), sort)]
                    : null
                }
                sortable={true}
                sort={sort}
                onSortChange={sortChange}
              >
                <GridNoRecords>
                  {cardlist != null ? "No records available" : <Loader />}
                </GridNoRecords>
                {/* <Column
                  width="34px"
                  headerCell={VisualizationHeaderCell}
                  cell={CellWithVisualizationIcon}ViewCell
                /> */}
                <Column
                  width="40px"
                  headerCell={PinHeaderCell}
                  cell={PinCardCell}
                  data={cardlist}
                />
                <Column
                  width="calc(100% - 154px)"
                  field="widgetName"
                  title="Card"
                />
                {!context.mediaCategory.sm && (
                  <Column width="100px" title="Engagement" cell={CategoryCell} />
                )}
                {!context.mediaCategory.sm && (
                  <Column width="80px" title="Preview" field="visualisationID" cell={ViewCell} />
                )}
                {!context.mediaCategory.sm && (
                  <Column width="130px" field="collaborationTypeID" title="Activity" cell={TypeCell} />
                )}
                <Column
                  width="88px"
                  title="Actions"
                  cell={AddCardCell}
                  headerCell={CenteredHeader}
                />
              </Grid>
            </Flex.Item>
          </Flex>
          {openPreview && (
            <Dialog
              autoFocus={true}
              className="previewDialog"
              title={previewObj.widgetName}
              onClose={() => setOpenPreview(false)}
            >
              <WidgetPreview
                widgetID={previewObj.cardWidgetID}
                viewTypeID={previewObj.cardViewTypeID}
              />
            </Dialog>
          )}
        </>
      )}
    </WindowContext.Consumer>
  );
};

const AddCard = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [category, setCategory] = useState("isAll");
  const [categoryValue, setCategoryValue] = useState(1);
  const [card, setCard] = useState(null);
  const [open, setOpen] = useState(false);
  const [dashboardId, setDashboardId] = useState(props.dashboardId);
  const [enableAllJoyRideTips, setEnableAllJoyRideTips] = useState(false);
  let cards = props.AddcardList; console.log(cards);
  const handleCategoryChange = (_categoryIndex, _category, _categoryValue) => {
    setSelectedCategory(_categoryIndex);
    setCategoryValue(_categoryValue);
    setCategory(_category);
  };
  const onCancel = () => {
    setOpen(false);
    setCard(null);
  };
  const onConfirm = () => {
    setOpen(true);
    //setCard(null);
  };
  const onOpen = (cardData) => {

    setCard(cardData);
    setOpen(true);
    introClickHit();
  };

  useEffect(() => {
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboardCards(dashboardId, token).then((response) => {
        if (response !== undefined && response !== " ") {
          global.localStorage.setItem("TestItems", response.length);
        }
      });
    });
  }, []);

  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    const dummy = global.localStorage.getItem("demoGlobal");
    setIsDemo(dummy);
    console.log(dummy)

  }, [])

  const [{ run, steps, stepIndex, HSAClick }, setState] = useSetState({
    stepIndex: 0,
    HSAClick: false,
    run: true,
    steps: [
      {
        title: "Card filter section",
        content: "Select filter to show cards as per selected category.",
        locale: { skip: <strong>Skip</strong> },
        placement: "right-start",
        target: "#cardCatSeg",
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: false,
      },
      {
        title: "Card List",
        content: "Lists all cards avilable.",
        locale: { skip: <strong>Skip</strong> },
        target: "#cardListSeg",
        disableOverlayClose: true,
        disableBeacon: true,
        spotlightClicks: false,

      },
      {
        content: "Hit add to get started with your first card.",
        locale: { skip: <strong>Skip</strong> },
        placement: "bottom",
        target: "#introCardAdd",
        spotlightClicks: true,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
      }
    ]
  })

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      // setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      if (HSAClick) {
        if (index === 2) {
          setTimeout(() => {
            setState({ run: true })
          }, 400)
        }
      }
      else if (index === 3 && action === ACTIONS.PREV) {
        //document.querySelector('tbody').lastChild.lastChild.firstChild.firstChild.setAttribute('id', 'testId');
        setState({
          run: false,
          HSAClick: false,
          stepIndex: nextStepIndex
        })
        setTimeout(() => {
          setState({ run: true })
        }, 400)
      } else {
        // Update state to advance the tour
        setState({
          HSAClick: false,
          stepIndex: nextStepIndex
        })
      }
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false });
    }
  };

  const introClickHit = () => {
    setState({ run: true })

    if (stepIndex === 2) {
      setState({
        run: stepIndex === 2 ? false : run,
        HSAClick: !HSAClick,
        stepIndex: stepIndex
      })
    }
  }


  return (
    <>
      {isDemo && enableAllJoyRideTips && <ReactJoyride
        continuous
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        stepIndex={stepIndex}
        showProgress={true}
        styles={{
          options: {

            primaryColor: 'rgb(98, 100, 167)',
            zIndex: 1000,
          }
        }}
      />}
      <Flex
        className="addcardcontainer"
        gap="gap.small"
        padding="padding.medium"
        style={{
          height: "calc(100vh - 43px)",
          //maxHeight: "100%"
        }}
      >
        <Flex.Item size="size.quarter">
          <Segment styles={{ overflow: "auto" }} id="cardCatSeg">
            <CardCategories
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
            />
          </Segment>
        </Flex.Item>

        <Flex.Item size="size.half" grow>
          <Segment id="cardListSeg">
            {props.userFiltersData ? (
              <>
                <CardsList
                  // demo={props.demo}

                  dashboardId={props.dashboardId}
                  userFiltersData={props.userFiltersData}
                  handleOpen={props.handleOpen}
                  onOpen={onOpen}
                  addcardsList={props.AddcardList}
                  cards={//card
                    category === "isAll"
                      ? cards//.sort((a, b) => a.widgetName.localeCompare(b.widgetName))
                        .sort((a, b) => {
                          if (a.isPinned && b.isPinned) {
                            if (a.dateInserted > b.dateInserted)
                              return -1;
                            else
                              return 1;
                          }
                          else {
                            if (a.isPinned) {
                              return -1;
                            } else if (b.isPinned) {
                              return 1;
                            }
                          }
                        })
                      : cards
                        .filter((card) => card[category] === categoryValue)
                        .sort((a, b) => {
                          if (a.isPinned && b.isPinned) {
                            if (a.dateInserted > b.dateInserted)
                              return -1;
                            else
                              return 1;
                          }
                          else {
                            if (a.isPinned) {
                              return -1;
                            } else if (b.isPinned) {
                              return 1;
                            }
                          }
                        })
                  }
                />
              </>
            ) : (
              <Loader style={{ height: "100%" }} />
            )}
          </Segment>
        </Flex.Item>
      </Flex>
      {open && (
        <Dialog

          autoFocus={true}
          className="settingsDialog"
          title={card.widgetName}
          onClose={() => onCancel()}
        >
          <SingleCardSettings
            demo={props.demo}
            cardData={card}
            dashboardId={props.dashboardId}
            submitHandler={onConfirm}
            cancelHandler={onCancel}
            userFiltersData={props.userFiltersData}
            cardList={props.AddcardList}
          />
        </Dialog>
      )}
    </>
  );
};

export default AddCard;
