import "./hscProgress.scss"

const HSCProgress = ({ progress = 0, progBarH = 6, label, progressLabel = true, zones = [20, 40, 60, 80], }) => {
  return (
    <>
      <div className="hsc-progress">
        <div className="progress-label">
          <label className="m-0 text-nowrap">
            {(!label) ? "Progress" : label}
          </label>
        </div>
        <div className="wrapper">
          <div className="progress-main" style={{ height: `${progBarH}px` }}>
            <div className="couple-prog-gradient" style={{ height: `${progBarH}px` }}>
              <div className='danger-zone-l' style={{ width: `${zones[0]}%` }}></div>
              <div className='warning-zone-l' style={{ width: `${zones[1] - zones[0]}%` }}></div>
              <div className='success-zone' style={{ width: `${zones[2] - zones[1]}%` }}></div>
              <div className='warning-zone-r' style={{ width: `${zones[3] - zones[2]}%` }}></div>
              <div className='danger-zone-r' style={{ width: `${100 - zones[3]}%` }}></div>
            </div>
            {progress ? <div className="progress-set-mark" style={{ left: `${progress}%`, top: `${-(progBarH / 2 - 1)}px` }}>
              <div className="cL mx-auto border-dark" style={{ height: `${progBarH + 4}px`, width: '0px', borderRadius: `${(progBarH + 4) / 2}px` }}></div>
            </div> : <></>}
          </div>
          {progressLabel && <div className="progress-value">
            <label>{progress ? `${progress}%` : 'NA'}</label>
          </div>}
        </div>
      </div>
    </>
  )
}

export default HSCProgress