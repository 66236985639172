import React from 'react';
import TeamVibeAPIService from '../../../services/TeamVibesApiService';
import { useState, useEffect } from 'react';
import AuthHelper from '../../../services/auth-helper';
import { Grid, GridNoRecords, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Flex, Dialog, Button, Loader, Pill, FlexItem, Form, Dropdown, Box, Text, Popup, Checkbox, Input, Status, Divider, SplitButton, FormInput, FormDropdown, FormButton, Segment, FormTextArea, Card, RadioGroup, FormRadioGroup } from "@fluentui/react-northstar";
import { TrashCanIcon, ChevronStartIcon, EditIcon, AddIcon, InfoIcon, SearchIcon, PlayIcon, EyeFriendlierIcon, EyeSlashIcon, EyeIcon, ArrowLeftIcon, ContactGroupIcon, ArrowRightIcon, AudienceIcon } from "@fluentui/react-icons-northstar";
import { Dialog as KendoDialog } from "@progress/kendo-react-dialogs";
import WindowContext from "../../Shared/Context/Context";
import { getter } from "@progress/kendo-react-common";
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import { MultiSelect } from "@progress/kendo-react-dropdowns";


const TeamVibeCellWithActionIcon = (props) => {
    const data = props.dataItem;
    const [deleting, setDeleting] = useState(false);
    return (
        <WindowContext.Consumer>
            {(context) => (
                <td>
                    <Flex hAlign="center" gap="gap.small">
                        {
                            props.dataItem.tenantId > 0 && props.dataItem.questionCategoryId == '-1' ?
                                <>
                                    <Button
                                        tinted
                                        icon={<EditIcon />}
                                        title='Edit'
                                        disabled={deleting}
                                        iconOnly
                                        size="small"
                                        onClick={() => {
                                            props.handleAddEditClick(true, data);
                                        }}
                                    />
                                    <Dialog
                                        cancelButton="Cancel"
                                        confirmButton="Delete"
                                        onConfirm={() => {
                                            setDeleting(true);
                                            props.handleDelete(data.id);
                                        }
                                        }
                                        closeOnOutsideClick={false}
                                        defaultOpen={false}
                                        styles={{ maxWidth: "500px" }}
                                        trigger={
                                            <Button
                                                text
                                                className="text-danger"
                                                iconOnly
                                                title='Delete'
                                                size="small"
                                                icon={<TrashCanIcon />}
                                                disabled={deleting}
                                                loading={deleting}
                                            // onClick={() => { }}
                                            />}
                                        content={"Are you sure you want to delete this Question?"}
                                    />
                                </>
                                : <></>
                        }
                    </Flex>
                </td>
            )}
        </WindowContext.Consumer>
    );
};



const TeamVibeQuestionGrid = (props) => {
    const DATA_ITEM_KEY = "id";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const initialDataState = { skip: 0, take: 10 };


    const [searchInput, setSearchInput] = useState("");
    const [sort, setSort] = useState([]);
    const [page, setPage] = React.useState(initialDataState);
    const [priorityMap, setPriorityMap] = useState(props.questionPriorities ? props.questionPriorities.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {}) : {});
    const [categoryMap, setCategoryMap] = useState(props.questionCategories ? props.questionCategories.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {}) : {});

    const [dataState, setDataState] = React.useState(null);
    const [selectedState, setSelectedState] = React.useState({});

    const [userData, setUserData] = useState([])

    const onSelectionChange = (event) => {
        debugger
        if (props.allowSelection) {
            const checked = event.syntheticEvent.target.checked;
            const dataItemKey = idGetter(event.dataItem);
            debugger
            const newSelectedState = { ...selectedState, [dataItemKey]: checked };
            debugger
            setSelectedState(newSelectedState);
            props.setSelectedQuestions(newSelectedState);
        }
    };

    const onHeaderSelectionChange = React.useCallback((event) => {
        if (props.allowSelection) {
            const checkboxElement = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {};
            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
            props.setSelectedQuestions(newSelectedState);
        }
    }, []);

    // React.useEffect(() => {
    //     if (props.questions) {
    //         setDataState(props.questions?.map((dataItem) =>
    //             Object.assign(
    //                 {
    //                     [SELECTED_FIELD]: false,
    //                 },
    //                 dataItem
    //             )
    //         ))
    //     }
    // }, [props.questions])

    const pageChange = (event) => {
        setPage(event.page);
    };
    const sortChange = (event) => {
        if (event.sort.length === 0) {
            setSort(event.sort);
            return;
        }

        var sortOrder = event.sort[0].dir;
        var columnName = event.sort[0].field;
        if (columnName === "tag") {
            let sortArray = [{ field: 'tag', dir: sortOrder }];
            setSort(sortArray);
        } else
            if (columnName === "category") {
                let sortArray = [{ field: 'category', dir: sortOrder }];
                setSort(sortArray);
            } else {
                setSort(event.sort);
            }
    };
    const expandChange = (event) => {
        debugger
        let newData = userData.map((item) => {
            if (item.userName === event.dataItem.userName) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        debugger
        //setUserData(newData);
    };

    const TeamVibeCategoryCell = (p) => {
        return (
            <td>
                <Flex className="question-category-cell" gap="gap.small">
                    {
                        p.dataItem && p.dataItem[p.field] ?
                            p.dataItem[p.field].split(',').map(m => {
                                return categoryMap[m] != null ?
                                    <span key={`${p.dataItem.id}_${p.field}_${m}`}>
                                        {categoryMap[m].header}
                                    </span> : <></>
                            }
                            ) : <></>
                    }
                </Flex></td>
        )
    }
    const TeamVibePriorityCell = (p) => {
        // {
        //     p.dataItem && p.dataItem[p.field] && priorityMap[p.dataItem[p.field]] != null ?
        //         <span key={`${p.dataItem.id}_${p.field}_${p.dataItem[p.field]}`}>
        //             {priorityMap[p.dataItem[p.field]].header}
        //         </span> : <></>
        // }
        switch (priorityMap[p.dataItem[p.field]].header) {
            case "High":

                return (
                    <td>
                        <Status className='mr-1' state="error" /><Text content="High" />
                    </td>
                );
            case "Medium":

                return (
                    <td>
                        <Status className='mr-1' state="warning" /><Text content="Medium" />
                    </td>
                );
            case "Low":

                return (
                    <td>
                        <Status className='mr-1' state="success" /><Text content="Low" />
                    </td>
                );
            default:
                return (
                    <td>
                        NA
                    </td>
                );
        }
    }
    const TeamVibeActionCell = (p) => {
        return (
            <TeamVibeCellWithActionIcon
                {...p}
                handleDelete={(qId) => props.handleQuestionDelete(qId)}
                handleAddEditClick={(isEdit, q) => props.handleAddEditQuestionClick(isEdit, q)}
            />
        );
    };

    const TeamVibeResponseType = (p) => {
        switch (p.dataItem.questionTypeId) {
            case 1:
                return (
                    <td>
                        <span style={{ fontSize: "1.2rem" }}>
                            ⭐⭐
                        </span>
                        <span style={{ fontSize: "1.2rem", color: 'transparent', textShadow: '0 0 lightgray' }}>
                            ⭐⭐⭐
                        </span>
                    </td>
                );
            case 2:
                return (
                    <td>
                        <span style={{ fontSize: "1.2rem" }}>
                            😡😢🙂😊🤩
                        </span>
                    </td>
                );
            case 3:
                return (
                    <td>

                        <span className='badge badge-secondary m-1 p-1'>Strongly Agree</span>
                        <span className='badge badge-secondary m-1 p-1'>Agree</span>
                        <span className='badge badge-secondary m-1 p-1'>Neutral</span>
                        <span className='badge badge-secondary m-1 p-1'> Disgree</span>
                        <span className='badge badge-secondary m-1 p-1'>Strongly Disagree</span>

                    </td>
                );
            default:
                return null;
        }
    }

    return (
        <WindowContext.Consumer>
            {(context) => <>

                <div>
                    <Flex className='mb-3' gap="gap.small" fill>
                        <Input
                            fluid
                            icon={<SearchIcon />}
                            clearable
                            value={searchInput}
                            placeholder="Search Question..."
                            onChange={(e, { name, value }) => {
                                setPage(initialDataState);
                                setSearchInput(value);
                            }}
                        />
                        {
                            props.allowCrudActions ?
                                <FlexItem push>
                                    <Button
                                        content="New Question"
                                        icon={<AddIcon />}
                                        iconPosition="before"
                                        aria-label="add question"
                                        primary title="Add new question"
                                        onClick={() => props.handleAddEditQuestionClick(false, null)}
                                    />
                                </FlexItem> : <></>
                        }

                    </Flex>
                    <Grid
                        className="teamvibe-grid"
                        style={{ maxHeight: "calc(100vh - 115px)" }}
                        detail={context.mediaCategory.sm ? 'CustomDetailedComponent' : null}
                        expandField="expanded"
                        onExpandChange={expandChange}
                        data={
                            props.questions != null
                                ? orderBy(props.questions
                                    .filter((vibe) =>
                                        vibe.questionHeader.toLowerCase().includes(searchInput.toLowerCase()) ||
                                        vibe.questionSubHeader.toLowerCase().includes(searchInput.toLowerCase())
                                    ), sort)
                                    .slice(page.skip, page.take + page.skip)
                                // .map((item) => ({
                                //     ...item,
                                //     [SELECTED_FIELD]: selectedState[idGetter(item)],
                                // }))
                                : null
                        }
                        // data={dataState != null ? dataState.map((item) => ({
                        //     ...item,
                        //     [SELECTED_FIELD]: selectedState[idGetter(item)],
                        // })) : null}
                        skip={page.skip}
                        take={page.take}
                        total={props.questions != null ? props.questions.filter((vibe) => vibe.questionHeader.toLowerCase().includes(searchInput.toLowerCase()) || vibe.questionSubHeader.toLowerCase().includes(searchInput.toLowerCase())).length : 0}
                        pageable={{
                            buttonCount: context.mediaCategory.sm ? 2 : 10,
                            info: true,
                        }}
                        onPageChange={pageChange}
                        sortable={true}
                        sort={sort}
                        onSortChange={sortChange}
                        dataItemKey={DATA_ITEM_KEY}
                    // selectedField={SELECTED_FIELD}
                    // selectable={{ enabled: false, drag: false, cell: false, multiple: true }}
                    // onSelectionChange={onSelectionChange}
                    // onHeaderSelectionChange={onHeaderSelectionChange}
                    // {...gridSelectionProps}
                    >
                        <GridNoRecords>
                            {props.questions != null ? "No records available" : <Loader />}
                        </GridNoRecords>
                        {
                            props.allowSelection && <Column
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    props.questions != null ? props.questions.findIndex((item) => !selectedState[idGetter(item)]) === -1 : false
                                }
                            />
                        }
                        <Column width="250px" title="Question Description" field="questionHeader" />
                        {/* <Column width="300px" title="Details" field="questionSubHeader" /> */}
                        <Column width="150px" title="Category" field="questionCategoryId" cell={TeamVibeCategoryCell} />
                        <Column width="100px" title="Priority" field="questionPriorityId" cell={TeamVibePriorityCell} />
                        <Column width="150px" title="Response Type" field="questionTypeId" cell={TeamVibeResponseType} />
                        {
                            props.allowCrudActions ? <Column width="100px" title="Actions" cell={TeamVibeActionCell} /> : <></>
                        }
                    </Grid>
                </div>
                {
                    (props.allowCrudActions && props.openAddEditDialog && props.editQuestionDetail != null) ?
                        <KendoDialog
                            className="editQuestionDialog"
                            onClose={() => props.onCancelAddEdit()}
                            title={props.edit ? "Edit Question" : "Add New Question"}
                        >
                            <VibeQuestionForm isEdit={props.edit} question={props.editQuestionDetail} submitHandler={(isEditMode, isSuccessful) => props.handleAddEditQuestionSubmit(isEditMode, isSuccessful)}
                                cancelHandler={props.onCancelAddEdit} questionTypes={props.questionTypes} />
                        </KendoDialog> : <></>
                }
            </>
            }
        </WindowContext.Consumer>
    )
}

const TeamVibeConfigurator = (props) => {
    const [selectedcategories, setSelectedCategories] = useState(props.wellbeingFormTemaplate.categories != null && props.wellbeingFormTemaplate.categories.length > 0 ? props.wellbeingFormTemaplate.categories : []);
    const [wellbeingFormTemaplate, setWellbeingFormTemplate] = useState(props.wellbeingFormTemaplate);
    const [loaded, setLoaded] = useState(false);

    const [showQuestions, setShowQuestions] = useState(false);
    const [allowCrudOperations, setAllowCrudOperations] = useState(false);
    const [editQuestionDetail, setEditQuestionDetail] = useState(null);
    const [edit, setEdit] = useState(false);
    const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [viewCatgoryId, setViewCategoryId] = useState(null);

    const [gridQuestions, setGridQuestions] = useState([]);

    function getQuestions() {
        AuthHelper.getAccessToken(function (token) {
            TeamVibeAPIService.GetTeamVibesQuestions(token)
                .then((response) => {
                    if (response != undefined && response != null) {
                        debugger
                        setQuestions(response);
                        if (viewCatgoryId != null || viewCatgoryId != undefined) {
                            updateGridQuestions(viewCatgoryId, response)
                        }
                    }
                });
        });
    }
    useEffect(() => {
        // setSearchInput("");
        getQuestions();
        setLoaded(true);
    }, []);

    const handleQuestionDelete = (questionID) => {
        debugger
        AuthHelper.getAccessToken(function (token) {
            TeamVibeAPIService.DeleteTeamVibesQuestions(questionID, token).then(
                (response) => {
                    if (response) {
                        let q = questions.filter((item) => item.id !== questionID);
                        setQuestions(q);
                        if (viewCatgoryId != null || viewCatgoryId != undefined) {
                            updateGridQuestions(viewCatgoryId, q)
                        }
                    }
                }
            );
        });
    };

    const handleAddEditQuestionClick = (isEdit, dataItem) => {
        debugger
        if (isEdit) {
            setEditQuestionDetail(dataItem);
        }
        else {
            setEditQuestionDetail({
                id: -1,
                questionCategoryId: "",
                questionHeader: "",
                questionPriorityId: 1,
                questionSubHeader: "",
                questionTypeId: 1
            });
        }

        setEdit(isEdit)
        setOpenAddEditDialog(true);
    };

    const onCancelAddEdit = () => {
        debugger
        setOpenAddEditDialog(false);
        setEdit(false);
        setEditQuestionDetail(null);
    };

    const handleAddEditQuestionSubmit = (isEditMode, isSuccessful) => {
        debugger
        if (isSuccessful) {
            setOpenAddEditDialog(false);
            setEdit(false);
            setEditQuestionDetail(null);
            getQuestions();
        }
    };

    const handleSaveTeamVibeConfiguration = () => {
        debugger
        if (wellbeingFormTemaplate != null && wellbeingFormTemaplate.frequency != null && selectedcategories != null && selectedcategories.length > 0) {

            let param = {
                Id: 0,
                FrequencyId: wellbeingFormTemaplate.frequency.id,
                PriorityId: '',
                CategoriesId: selectedcategories.join(','),
                AllQuestions: true,
                // RunstateId: -1,
                QuestionIdList: '',
                // TenantId: 0,
                // GroupId: ""
            };
            AuthHelper.getAccessToken(function (token) {
                TeamVibeAPIService.AddOrUpdateWellBeingTemplate(param, token)
                    .then((response) => {
                        if (response != undefined && response != null) {
                            props.handleConfiguratorUpdate(true);
                        }
                        else {
                            props.handleConfiguratorUpdate(false);
                        }
                    });
            });
        }
    };

    const handleViewQuestionsClick = (categoryId) => {
        setViewCategoryId(Number(categoryId));
        setAllowCrudOperations(Number(categoryId) === -1);
        updateGridQuestions(categoryId, questions);
        setShowQuestions(true);
    }

    const updateGridQuestions = (categoryId, questionList) => {
        // if (selectedCategories.length === 0) {
        //     setGridQuestions(questionList);
        // } else {
        //     const updatedGridQuestions = questionList.filter(question => {
        //         const questionCategories = question.questionCategoryId.split(',').map(id => parseInt(id.trim(), 10));
        //         return questionCategories.some(category => selectedCategories.includes(category));
        //     });
        //     setGridQuestions(updatedGridQuestions);
        // }
        let updatedGridQuestions = questionList.filter(q => q.questionCategoryId.split(',').map(Number).includes(categoryId));
        setGridQuestions(updatedGridQuestions);
    };
    return (
        <WindowContext.Consumer>
            {(context) => loaded && wellbeingFormTemaplate != null ? (
                <div className='mt-4'>
                    {
                        showQuestions === false ?
                            <div className="mb-4">
                                <Box>
                                    <div className='mx-2 px-2'>
                                        <Text content="Please select the categories for which you would like to assess and collect the data for your workplace." className="text-nowrap" weight="semibold" color="brand" />
                                    </div>
                                    <div className='category-checks p-2 mx-2 mt-2 mb-4 rounded'>
                                        {props.questionCategories?.map((category, index) => (
                                            <div className="chk-card">
                                                <Checkbox
                                                    key={category.id}
                                                    className={`${selectedcategories.includes(category.id) ? 'text-primary' : 'text-secondary'} mr-auto`}
                                                    label={<span className='chk-header'>{category.header}<br /><br /><span className='chk-sub-header'>{category.subtext}</span></span>}
                                                    checked={selectedcategories.includes(category.id)}
                                                    onClick={(event, { checked }) => {
                                                        let updatedSelectedCategories = [...selectedcategories];
                                                        if (checked) {
                                                            updatedSelectedCategories = [...updatedSelectedCategories, category.id];
                                                        }
                                                        else {
                                                            updatedSelectedCategories = updatedSelectedCategories.filter(f => f != category.id);
                                                        }
                                                        setSelectedCategories(updatedSelectedCategories);
                                                    }}
                                                />
                                                <div className="d-flex flex-column align-items-end justify-content-end question-count-label">
                                                    <Button
                                                        size='small'
                                                        tinted
                                                        onClick={() => handleViewQuestionsClick(category.id)}
                                                        content={`${questions.filter(question => {
                                                            const questionCategories = question.questionCategoryId.split(',').map(id => parseInt(id.trim(), 10));
                                                            return questionCategories.includes(category.id);
                                                        }).length
                                                            } questions`} title="view questions" />
                                                </div>
                                                {/*<Button iconOnly text icon={<EyeFriendlierIcon />} title="View Questions" onClick={() => handleViewQuestionsClick(category.id)} /> */}
                                            </div>
                                        ))}
                                    </div>
                                    <div className={context.mediaCategory.sm ? "flex-column filterLbl d-flex pb-3" : "filterLbl d-flex pb-3"} style={{ columnGap: "1rem" }}>
                                        <div className="col-md-12 filterLblRow d-flex align-items-center">
                                            <div className="filterLblRow-txt pr-2" style={{ minWidth: "80px" }}>
                                                <Text content="Please select the frequency for which you would like to collect the data for your workplace. " className="text-nowrap" weight="semibold" color="brand" />
                                            </div>

                                            <Dropdown
                                                inline
                                                fluid
                                                className="mr-2"
                                                items={props.vibeRunFrequencies}
                                                placeholder="Select frequencey"
                                                checkable
                                                value={wellbeingFormTemaplate.frequency}
                                                onChange={(e, { value }) => { setWellbeingFormTemplate({ ...wellbeingFormTemaplate, frequency: value }) }}
                                            />
                                            <div className="filterLblRow-icn ml-2">
                                                <Box>
                                                    <Popup
                                                        trigger={<InfoIcon outline size="large" />}
                                                        content={<>
                                                            <p>Select frequency of the questions to be circulated</p>
                                                        </>}
                                                        pointing
                                                        trapFocus
                                                        position={"after"}
                                                        align={"center"}
                                                        on="hover"
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                                <Flex space="between" className="my-2" styles={{ position: `${context.mediaCategory.sm ? ".8em" : ""}`, bottom: "0px", right: "16px" }}>
                                    <Box />
                                    <Flex gap="gap.small">
                                        <Button
                                            Content
                                            content="Cancel"
                                            onClick={() => props.onCancelClick(false)}
                                        />
                                        <Button
                                            // icon={<PlayIcon />}
                                            primary
                                            iconPosition='before'
                                            content="Save"
                                            disabled={!(wellbeingFormTemaplate != null && wellbeingFormTemaplate.frequency != null && selectedcategories != null && selectedcategories.length > 0)}
                                            onClick={handleSaveTeamVibeConfiguration}
                                        />
                                    </Flex>
                                </Flex>
                            </div>
                            : <></>
                    }

                    {
                        questions != null && showQuestions === true ?
                            <div className='configurator-grid-holder' >
                                <TeamVibeQuestionGrid
                                    allowSelection={false}
                                    allowCrudActions={allowCrudOperations}
                                    openAddEditDialog={openAddEditDialog}
                                    edit={edit}
                                    editQuestionDetail={editQuestionDetail}
                                    questions={gridQuestions}
                                    questionCategories={props.questionCategories}
                                    questionPriorities={props.questionPriorities}
                                    questionTypes={props.questionTypes}
                                    handleQuestionDelete={(qId) => handleQuestionDelete(qId)}
                                    handleAddEditQuestionClick={(isEdit, q) => handleAddEditQuestionClick(isEdit, q)}
                                    handleAddEditQuestionSubmit={(isEditMode, isSuccessful) => handleAddEditQuestionSubmit(isEditMode, isSuccessful)}
                                    onCancelAddEdit={() => onCancelAddEdit()}
                                />
                                <Button
                                    primary
                                    text
                                    Content
                                    icon={showQuestions ? <ArrowLeftIcon /> : <EyeFriendlierIcon />}
                                    content={showQuestions ? "Back to configuration" : "show questions"}
                                    onClick={() => setShowQuestions(!showQuestions)}
                                />
                            </div>
                            : <></>
                    }
                </div>
            )
                : <Loader label="loading..." />
            }
        </WindowContext.Consumer>
    )
}



const TeamVibeConfig = () => {
    const [questionCategories, setQuestionCategories] = useState([]);
    const [questionPriorities, setQuestionPriorities] = useState([]);
    const [vibeRunFrequencies, setVibeRunFrequencies] = useState([]);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [wellbeingFormTemaplate, setWellbeingFormTemplate] = useState(null);

    const [viewType, setViewType] = useState(0) // 0: home, 
    const [vibeConfiguratorVisible, setVibeConfiguratorVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    function getTeamVibeHome() {
        AuthHelper.getAccessToken(function (token) {
            TeamVibeAPIService.GetTeamVibeHome(token)
                .then((response) => {
                    if (response != undefined && response != null) {
                        setViewType(response.viewType);

                        let listPriorities = [], listFrequencies = [], listCategories = [], listTypes = [];

                        if (response.priorities !== null && response.priorities != undefined) {
                            listPriorities = response.priorities?.map(ele => ({
                                id: ele.id,
                                header: ele.questionPriorityDesc,
                                key: ele.id
                            }));
                        }
                        if (response.categories !== null && response.categories != undefined) {
                            listCategories = response.categories?.map(ele => ({
                                id: ele.id,
                                header: ele.questionCategoryName,
                                subtext: ele.questionCategoryDesc,
                                key: ele.id
                            }));

                            listCategories = [...listCategories, { id: -1, header: "Custom defined", key: -1 }];
                        }
                        if (response.runFrequencies !== null && response.runFrequencies != undefined) {
                            listFrequencies = response.runFrequencies?.map(ele => ({
                                id: ele.id,
                                header: ele.frequencyName,
                                key: ele.id
                            }));
                        }
                        if (response.questionTypes !== null && response.questionTypes != undefined) {
                            listTypes = response.questionTypes?.map(ele => ({
                                id: ele.id,
                                header: ele.questionTypeName,
                                key: ele.questionTypeDesc
                            }));
                        }

                        setQuestionCategories(listCategories);
                        setQuestionPriorities(listPriorities);
                        setVibeRunFrequencies(listFrequencies);
                        setQuestionTypes(listTypes);

                        debugger
                        if (response.wellBeingForm != null) {
                            let templateform = { ...response.wellBeingForm };
                            if (response.wellBeingForm.allQuestions) {
                                templateform = { ...templateform, questions: [] }
                            }
                            if (response.wellBeingForm.frequencyId) {
                                templateform = { ...templateform, frequency: listFrequencies.find((f) => { return f.id == response.wellBeingForm.frequencyId }) }
                            }
                            if (response.wellBeingForm.priorityId != null) {
                                let selectedPriorities = response.wellBeingForm.priorityId.split(',').map(Number);
                                templateform = { ...templateform, priorities: listPriorities.filter((p) => selectedPriorities.includes(p.id)) }
                            }
                            if (response.wellBeingForm.categoriesId != null) {
                                debugger
                                let selectedcategories = response.wellBeingForm.categoriesId.length > 0 ? response.wellBeingForm.categoriesId.split(',').map(Number) : [];
                                templateform = { ...templateform, categories: listCategories.filter((c) => selectedcategories.includes(c.id)).map(m => m.id) }
                            }
                            if (response.wellBeingForm.groupId !== null) {
                                debugger
                                let selectedGroups = response.wellBeingForm.groupId.length > 0 ? response.wellBeingForm.groupId.split(',').map(Number) : [];
                                templateform = { ...templateform, groups: selectedGroups }
                            }

                            setWellbeingFormTemplate(templateform);
                        }
                    }
                    setLoading(false);
                });
        })
    }
    const handleConfiguratorUpdate = (isSuccess) => {
        if (isSuccess === true) {
            setLoading(true);
            getTeamVibeHome();
        }

        setVibeConfiguratorVisible(false);
    }

    const handleRunStatusUpdate = (isSuccess) => {
        if (isSuccess === true) {
            setLoading(true);
            getTeamVibeHome();
        }
    }

    useEffect(() => {
        getTeamVibeHome();
    }, []);

    return (
        <WindowContext.Consumer>
            {
                context => (loading ? <Loader label="loading..." /> :
                    <>
                        <div className="container h-100">
                            <div className="row align-items-center justify-content-center m-2">
                                <div className="col px-0 me-auto">
                                    <h5>Team Vibe</h5>
                                    <h>Welcome to our team vibe section. Your opinions matter to us, and this survey will help us understand your experiences and expectations at [Company Name]. The survey includes [number] questions and should take about [time estimate] to finish.</h>
                                </div>
                            </div>
                            {
                                viewType === -1 && vibeConfiguratorVisible === false ?
                                    <div className='vibe-set-start m-2'>
                                        <div className='vibe-get-start'>
                                            <Button content="Get Started" primary onClick={() => setVibeConfiguratorVisible(true)} />
                                            <div className='mt-2 text-secondary'>
                                                <h>Team vibe is not configured yet !</h>
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                viewType === 1 && vibeConfiguratorVisible === false ?
                                    <VibeHome
                                        wellbeingFormTemaplate={wellbeingFormTemaplate}
                                        setVibeConfiguratorVisible={(value) => setVibeConfiguratorVisible(value)}
                                        handleRunStatusUpdate={(isSuccess) => handleRunStatusUpdate(isSuccess)}
                                    /> : <></>
                            }
                            {
                                vibeConfiguratorVisible === true && wellbeingFormTemaplate != null &&
                                <TeamVibeConfigurator
                                    handleConfiguratorUpdate={(isSuccess) => handleConfiguratorUpdate(isSuccess)}
                                    onCancelClick={(value) => setVibeConfiguratorVisible(value)}
                                    questionCategories={questionCategories}
                                    questionPriorities={questionPriorities}
                                    vibeRunFrequencies={vibeRunFrequencies}
                                    wellbeingFormTemaplate={wellbeingFormTemaplate}
                                    questionTypes={questionTypes}
                                />
                            }
                            {/* {viewType === 0 ?
                                <TeamVibeQuestionGrid
                                    allowSelection={false}
                                    allowCrudActions={true}
                                    openAddEditDialog={openAddEditDialog}
                                    edit={edit}
                                    editQuestionDetail={editQuestionDetail}
                                    questions={questions}
                                    questionCategories={questionCategories}
                                    questionPriorities={questionPriorities}
                                    handleQuestionDelete={(qId) => handleQuestionDelete(qId)}
                                    handleAddEditQuestionClick={(isEdit, q) => handleAddEditQuestionClick(isEdit, q)}
                                    handleAddEditQuestionSubmit={(isEditMode, isSuccessful) => handleAddEditQuestionSubmit(isEditMode, isSuccessful)}
                                    onCancelAddEdit={() => onCancelAddEdit()}
                                /> : <></>
                            }
                            {
                                viewType === 1 ?
                                    <TeamVibeConfigurator
                                        questions={questions}
                                        questionCategories={questionCategories}
                                        questionPriorities={questionPriorities}
                                    /> : <></>
                            } */}
                            {/* <div className='row m-2'>
                                <div className='col px-0 text-right'>
                                    <Button
                                        icon={<PlayIcon />}
                                        content={"Run"}
                                        iconPosition="before"
                                        primary
                                    />
                                </div>
                            </div> */}
                        </div>
                    </>
                )
            }
        </WindowContext.Consumer>
    );
}
const VibeHome = (props) => {
    const [groups, setGroups] = useState([]);
    const [wellbeingFormTemaplate, setWellbeingFormTemplate] = useState(props.wellbeingFormTemaplate);
    const [selecetdGroups, setSelectedGroups] = useState([]);
    const [selectedButton, setSelectedButton] = useState(props.wellbeingFormTemaplate.runstateId);
    const [runOptions, setRunOptions] = useState([
        {
            name: 'selectedRunState',
            key: 0,
            label: 'Run for all company',
            value: 0,
        },
        {
            name: 'selectedRunState',
            key: 1,
            label: 'Run for selected groups',
            value: 1
        },
    ])
    const [openGroupPopUp, setOpenGroupPopUp] = useState(false);
    const [openPauseAlertPopUp, setOpenPauseAlertPopUp] = useState(false);
    const [selectedRunState, setSelectedRunState] = useState(props.wellbeingFormTemaplate.runstateId);
    const [runStatus, setRunStatus] = useState(false);
    const [isValid, setValid] = useState(props.wellbeingFormTemaplate != null && props.wellbeingFormTemaplate.runstateId >= 0 ? true : false);

    const [loading, setLoading] = useState(true);

    function getTeamVibeGroups() {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.GetGroups(token).then((response) => {
                debugger
                if (response != undefined && response != "") {
                    // var tempGroups = [];
                    debugger
                    // response.forEach((element) => {
                    //     var ob = {
                    //         groupId: element.groupId,
                    //         groupGUID: element.groupGUID,
                    //         groupName: element.groupName,
                    //         email: element.email,
                    //         groupTypeId: element.groupTypeId,
                    //         groupTypeName: element.groupTypeName,
                    //         memberCount: element.memberCount,
                    //         objectTypeID: element.objectTypeID,
                    //         reportingAccess: element.reportingAccess,
                    //         subscriptionId: element.subscriptionId,
                    //         visibilityId: element.visibilityId,
                    //         visibilityName: element.visibilityName,
                    //         administrativeAccess: element.administrativeAccess
                    //     };
                    //     if (element.objectTypeID !== 2)
                    //         tempGroups.push(ob);
                    // });

                    let allAvailableGroups = response.filter(f => f.objectTypeID !== 2).map(m => ({
                        key: Number(m.groupId),
                        value: Number(m.groupId),
                        header: m.groupName,
                        content: m.email
                    }));
                    let seletedGroupIds = wellbeingFormTemaplate?.groupId.split(',').map(m => Number(m));
                    setSelectedGroups(allAvailableGroups.filter(f => seletedGroupIds.includes(f.value)));
                    setGroups(allAvailableGroups);
                }
                else {
                    setGroups([]);
                }
                setLoading(false)
            });
        });
    }
    useEffect(() => {
        debugger
        getTeamVibeGroups();
    }, []);

    const isFormValid = (_runstateId, _groups) => {
        debugger
        let isVibeFormValid = (_runstateId == 0 || (_runstateId == 1 && _groups != null && _groups.length > 0));

        return isVibeFormValid;
    }
    const handleRunWithSelectionsClick = (showGroupPopup) => {
        setOpenGroupPopUp(showGroupPopup);
    };
    const handlePauseClick = (showPauseAlert) => {
        setOpenPauseAlertPopUp(showPauseAlert);
    }
    const handleRunStatusUpdateClick = (runState) => {
        
        if (isValid) {
            let param = {
                RunstateId: runState,
                GroupId: runState == 1 ? selecetdGroups.map(m => Number(m.value)).join(',') : "",
            };
            setLoading(true);
            AuthHelper.getAccessToken(function (token) {
                TeamVibeAPIService.UpdateWellBeingRunState(param, token)
                    .then((response) => {
                        if (response != undefined && response != null) {
                            debugger
                            //props.handleRunStatusUpdate(true);
                            setSelectedButton(runState);
                            setOpenGroupPopUp(false);
                            setOpenPauseAlertPopUp(false);
                            setLoading(false);
                        }
                        else {
                            setLoading(false);
                            //props.handleRunStatusUpdate(false);
                        }
                    });
            });
        }
    }


    return (
        <WindowContext.Consumer>
            {
                context => (loading ? <Loader label="loading..." /> :
                    <div>
                        {/*<div className="row">*/}
                        {/*    <div className="col">*/}
                        {/*        <SplitButton*/}
                        {/*            size="medium"*/}
                        {/*            menu={[*/}
                        {/*                {*/}
                        {/*                    key: 'runstatus',*/}
                        {/*                    content: 'Run for all company',*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    key: 'pause',*/}
                        {/*                    content: 'Run for selected groups',*/}
                        {/*                    onClick: () => handleRunWithSelectionsClick(true),*/}
                        {/*                },*/}
                        {/*            ]}*/}
                        {/*            button={{*/}
                        {/*                icon: <Status state="error" />,*/}
                        {/*                content: 'Paused',*/}
                        {/*                'aria-roledescription': 'splitbutton',*/}
                        {/*                'aria-describedby': 'instruction-message',*/}
                        {/*                //onClick: () => changeStatus(),*/}
                        {/*            }}*/}
                        {/*            toggleButton={{*/}
                        {/*                'aria-label': 'more options',*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        <div className="row text-right">
                            <div className="col">
                                <Button.Group
                                    buttons={[
                                        {
                                            disabled: selectedButton == 0 ? true : false, // true when running
                                            icon: selectedButton == 0 ? <Status state="success" /> : <AudienceIcon />, // <AudienceIcon /> icon replace when not running
                                            key: 'all',
                                            content: selectedButton == 0 ? 'Running for all' : 'Run for all',// replace with Run for all when not running
                                            title: 'Run for all',
                                            onClick: () => {  handleRunStatusUpdateClick(0);  },
                                        },
                                        {
                                            disabled: selectedButton == 1 ? true : false,
                                            icon: selectedButton == 1 ?<Status state="success" />:<ContactGroupIcon />, // <Status state="success" /> icon replace when running
                                            key: 'group',
                                            content: selectedButton == 1 ?'Running for selected groups':'Run for selected groups',
                                            title: 'Run for selected groups',
                                            onClick: () => handleRunWithSelectionsClick(true),
                                        },
                                        {
                                            disabled: selectedButton == -1 ? true : false,
                                            icon: <Status state="error" />,
                                            key: 'pause',
                                            content: selectedButton == -1 ?'Paused':'Pause', // replace title with paused when paused and disable it
                                            title: 'Pause', // replace title with paused when paused and disable it
                                            onClick: () =>  handlePauseClick(true),
                                        },
                                    ]}
                                />
                            </div>

                        </div>
                        <div>
                            <Button
                                tinted
                                icon={<ArrowRightIcon />}
                                iconPosition='after'
                                content="Configuration"
                                onClick={() => props.setVibeConfiguratorVisible(true)}
                            />
                        </div>
                        <div className='vibe-get-start'>
                            <div className='mt-2 text-secondary'>
                                <h>stats here as per configuration</h>
                            </div>
                        </div>

                        {
                            openGroupPopUp ?
                                <KendoDialog
                                    title={<Text size="large">Update running questions</Text>}
                                    onClose={() => setOpenGroupPopUp(false)}
                                // style={{ overflow: "scroll" }}
                                // width={context.mediaCategory.sm ? null : 600}
                                // height={context.mediaCategory.sm ? null : 336}
                                >
                                    <Box className="px-2">
                                        {/* <RadioGroup items={runOptions}
                                            defaultCheckedValue={wellbeingFormTemaplate?.runstateId >= 0 ? wellbeingFormTemaplate.runstateId : 0}
                                            onCheckedValueChange={(e, props) => {
                                                setSelectedRunState(props.value);
                                                setValid(isFormValid(props.value, selecetdGroups))
                                                //setWellbeingFormTemplate({...wellbeingFormTemaplate, runstateId: Number(props.value)})
                                            }}
                                        /> */}
                                        {
                                           
                                                <div className="container-fluid d-inline">
                                                    <div className={context.mediaCategory.sm ? "justify-content-center flex-column filterLbl d-flex pb-3" : "justify-content-center filterLbl d-flex pb-3"} style={{ columnGap: "1rem" }}>
                                                        <div className="filterLblRow d-flex align-items-center">
                                                            <div className="filterLblRow-txt pr-2">
                                                                <Text content="Select Group : " className="text-nowrap" weight="semibold" color="brand" />
                                                            </div>
                                                            <Dropdown
                                                                className="mr-2"
                                                                search
                                                                multiple
                                                                items={groups}
                                                                placeholder="Start typing a group name"
                                                                noResultsMessage="We couldn't find any matches."
                                                                value={selecetdGroups}
                                                                onChange={(e, { value }) => { setSelectedGroups(value); setValid(isFormValid(1, value)) }}
                                                            />
                                                            <div className="filterLblRow-icn ml-auto">
                                                                <Box>
                                                                    <Popup
                                                                        trigger={<InfoIcon outline size="large" />}
                                                                        content={<>
                                                                            <p>Select the groups you want to collect vibe for.</p>
                                                                        </>}
                                                                        pointing
                                                                        trapFocus
                                                                        position={"after"}
                                                                        align={"center"}
                                                                        on="hover"
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                        }

                                        <Flex space="between" className="my-2" styles={{ position: `${context.mediaCategory.sm ? ".8em" : ""}`, bottom: "0px", right: "16px" }}>
                                            <Box />
                                            <Flex gap="gap.small">
                                                <Button Content content="Cancel" onClick={() => {
                                                    setOpenGroupPopUp(false)
                                                    setSelectedRunState(wellbeingFormTemaplate.runstateId)
                                                    if (wellbeingFormTemaplate.runstateId == 1) {
                                                        let seletedGroupIds = wellbeingFormTemaplate?.groupId.split(',').map(m => Number(m));
                                                        setSelectedGroups(groups.filter(f => seletedGroupIds.includes(f.value)));
                                                    }
                                                }} />
                                                <Button content="Save" primary onClick={() => {  handleRunStatusUpdateClick(1) }} disabled={!isValid} />
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </KendoDialog>
                                : <></>
                        }
                        {openPauseAlertPopUp ? <KendoDialog
                            onClose={() => setOpenPauseAlertPopUp(false)}
                       >
                            <Box className="px-2">
                        
                                {

                                    <div className="container-fluid d-inline">
                                        Are you sure you want to pause this survey?
                                        
                                    </div>

                                }

                                <Flex space="between" className="my-2" styles={{ position: `${context.mediaCategory.sm ? ".8em" : ""}`, bottom: "0px", right: "16px" }}>
                                    <Box />
                                    <Flex gap="gap.small">
                                        <Button Content content="Cancel" onClick={() => {
                                            setOpenPauseAlertPopUp(false);
                                            
                                            }
                                        } />
                                        <Button content="Pause" primary onClick={() => {handleRunStatusUpdateClick(-1)}} disabled={!isValid} />
                                    </Flex>
                                </Flex>
                            </Box>
                        </KendoDialog> : <></>}
                    </div>
                )
            }
        </WindowContext.Consumer>
    )
}
const VibeQuestionForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [isValid, setValid] = useState(props.isEdit ? true : false);
    const [question, setQuestion] = useState(props.question);
    const [priorities, setPriorities] = useState([]);
    //const [categories, setCategories] = useState([]);

    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            TeamVibeAPIService.GetCategoriesAndPriorities(token)
                .then((response) => {
                    console.log(props.question)
                    if (response != undefined && response != null) {
                        debugger
                        if (response.questionPrioritiesList !== null && response.questionPrioritiesList != undefined) {
                            let priorities = response.questionPrioritiesList.map(m => ({ key: m.id, value: m.id, header: m.questionPriorityDesc }));
                            setPriorities(priorities);
                            question.questionPriority = priorities.find(f => f.key === question.questionPriorityId);
                        }
                        // if (response.questionsCategoriesList !== null && response.questionsCategoriesList != undefined) {
                        //     let categories = response.questionsCategoriesList.map(m => ({ key: m.id, value: m.id, header: m.questionCategoryName }));
                        //     setCategories(categories);
                        //     let categoryArray = question.questionCategoryId.split(',').reduce((acc, x) => acc.concat(+x), []);
                        //     question.questionCategories = categories.filter(f => categoryArray.includes(f.key));
                        // }
                    }

                    setLoading(false);
                });
        })
    }, []);

    useEffect(() => {
        setValid(isFormValid());
    }, [question])

    const isFormValid = () => {
        debugger
        let isVibeFormValid = question != null && question.questionHeader != null && question.questionHeader.trim().length > 0
            // && question.questionSubHeader != null && question.questionSubHeader.trim().length > 0
            && question.questionPriority != null && question.questionTypeId != null
            ;//&& question.questionCategories != null && question.questionCategories.length > 0;

        return isVibeFormValid;
    }

    const QuestionTypeCard = ({ qt, setQuestion, checkedValue }) => {
        const renderContent = () => {
            switch (qt.id) {
                case 1:
                    return (

                        <div class="row response-type mx-1">
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>⭐</div>
                                <div className='response-label'>Very Dissatisfied</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>⭐</div>
                                <div className='response-label'>Not Satisfied</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>⭐</div>
                                <div className='response-label'>Neutral</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>⭐</div>
                                <div className='response-label'>Satisfied</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>⭐</div>
                                <div className='response-label'>Very Satisfied</div>
                            </div>
                        </div>
                    );
                case 2:
                    return (
                        <div class="row response-type mx-1">
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>😡</div>
                                <div className='response-label'>Very Dissatisfied</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>😢</div>
                                <div className='response-label'>Not Satisfied</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>🙂</div>
                                <div className='response-label'>Neutral</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>😊</div>
                                <div className='response-label'>Satisfied</div>
                            </div>
                            <div className='col-auto px-2 py-1 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-emoji'>🤩</div>
                                <div className='response-label'>Very Satisfied</div>
                            </div>
                        </div>
                    );
                case 3:
                    return (
                        <div class="row response-type mx-1">
                            <div className='col-auto px-1 py-2 mx-0 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-label type2'>Strongly Agree</div>
                            </div>
                            <div className='col-auto px-1 py-2 mx-0 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-label type2'>Agree</div>
                            </div>
                            <div className='col-auto px-1 py-2 mx-0 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-label type2'>Neutral</div>
                            </div>
                            <div className='col-auto px-1 py-2 mx-0 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-label type2'>Disagree</div>
                            </div>
                            <div className='col-auto px-1 py-2 mx-0 my-1 rounded bg-edebe9 text-center'>
                                <div className='response-label type2'>Strongly Disagree</div>
                            </div>
                        </div>
                    );
                default:
                    return null;
            }
        };

        return (
            <>
                <RadioGroup
                    className='response-type-card rounded my-3'
                    items={[{
                        key: qt.id,
                        label: renderContent(qt.id),
                        value: qt.id,
                    }]}
                    checkedValue={checkedValue}
                    onCheckedValueChange={(e, item) => setQuestion({ ...question, questionTypeId: qt.id })}
                />
                {/* <div >
                        <h3 style={{
                            margin: '0 0 10px 0',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: '#333',
                        }}>
                            {qt.header}
                        </h3>
                        <p style={{
                            margin: '0',
                            fontSize: '14px',
                            color: '#666',
                        }}>
                            {qt.key}
                        </p>
                    </div> */}
                {/* {renderContent()} */}
            </>
        );
    };

    const questionSubmitHandler = () => {
        debugger
        if (isValid) {
            debugger
            setLoading(true);
            // let questionDetail = {...question,  }
            question.questionCategoryId = '-1'// question.questionCategories.map(m => m.key).join(',');
            question.questionPriorityId = question.questionPriority.key;
            question.questionHeader = question.questionHeader.trim();
            question.questionSubHeader = ''; // question.questionSubHeader.trim();
            AuthHelper.getAccessToken(function (token) {
                TeamVibeAPIService.AddorUpdateQuestion(question, token)
                    .then((response) => {
                        debugger
                        if (response !== null) {
                            debugger
                            props.submitHandler(props.isEdit, true);
                        }

                        setLoading(false);
                    });
            });
        }
    };



    return (
        <WindowContext.Consumer>
            {
                (context) => loading ? <Loader label="loading..." /> :
                    <Box>
                        <Form onSubmit={() => questionSubmitHandler()} className="questionEditForm p-2" >
                            <div className='mb-3'>
                                <FormDropdown
                                    label="Priority : "
                                    fluid
                                    placeholder="Select a priority"
                                    items={priorities}
                                    value={question.questionPriority}
                                    itemToValue={(obj) => { return obj.key; }}
                                    onChange={(e, { value }) => { setQuestion({ ...question, questionPriority: value }); }}
                                    noResultsMessage="We couldn't find any matches."
                                />
                            </div>
                            <div className='mb-1'>
                                <FormTextArea
                                    style={{ width: "100%" }}
                                    label="Question Description"
                                    placeholder='Question Description'
                                    value={question.questionHeader}
                                    autoFocus
                                    onChange={(e, { value }) => { setQuestion({ ...question, questionHeader: value }); }}
                                    required
                                    inline
                                />
                            </div>
                            {/* <div>
                                <FormInput
                                    style={{ width: "100%" }}
                                    label="Question Details"
                                    placeholder='Question Details'
                                    value={question.questionSubHeader}
                                    onChange={(e, { value }) => { setQuestion({ ...question, questionSubHeader: value }); }}
                                    required
                                    inline
                                />
                            </div> */}
                            <label className='mb-0'>Present options as:</label>

                            <div className='mx-auto'>
                                {props.questionTypes.map((qt) => (
                                    <QuestionTypeCard
                                        qt={qt}
                                        checkedValue={question.questionTypeId}
                                        setQuestion={setQuestion}
                                    />
                                ))}
                            </div>

                            {/* <div>
                                <FormDropdown
                                    fluid
                                    inline
                                    label="Categories"
                                    multiple
                                    placeholder="Select categories"
                                    items={categories}
                                    value={question.questionCategories}
                                    itemToValue={(obj) => { return obj.key; }}
                                    onChange={(e, { value }) => { setQuestion({ ...question, questionCategories: value }); }}
                                    noResultsMessage="We couldn't find any matches."
                                />
                            </div> */}
                            <Flex
                                styles={{
                                    marginTop: "30px",
                                    flexDirection: "row-reverse",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Flex.Item>
                                    <FormButton
                                        style={{ marginLeft: "0.625rem", marginRight: "0" }}
                                        primary
                                        className="mt-0 btnFormAction"
                                        content="Save"
                                        disabled={!isValid}
                                        onClick={() => questionSubmitHandler()}
                                    />
                                </Flex.Item>
                                <FormButton
                                    className="mt-0 btnFormAction"
                                    content="Cancel"
                                    onClick={() => props.cancelHandler()}
                                />
                            </Flex>
                        </Form>
                    </Box>
            }
        </WindowContext.Consumer>
    )
}

export default TeamVibeConfig;