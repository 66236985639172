import React, { useState, useEffect } from "react";
import { Form, Tooltip } from "@fluentui/react-northstar";
import { TimePicker, DatePicker, Calendar } from "@progress/kendo-react-dateinputs";
import "./SingleCardSettings.scss";
import {
  Loader,
  Flex,
  Segment,
  Header,
  Checkbox,
  Dialog,
  Button,
  Text,
  Input,
  FormInput,
  FormLabel,
  FormDropdown,
  FormField,
  FormCheckbox,
  FormButton
} from "@fluentui/react-northstar";
import {
  AcceptIcon,
  ExclamationTriangleIcon,
  CloseIcon,
  EyeFriendlierIcon,
  AddIcon,
  ErrorIcon
} from "@fluentui/react-icons-northstar";

import FiltersApiService from "../../../../services/FiltersApiService";
import AuthHelper from "../../../../services/auth-helper";
import {
  FilterTypes,
  cardFilters as card_filters,
  sentimentTypes,
  reactionTypes,
  callQualityTypes,
  teamModalityTypes,
  modalityTypes,
  msgParticipantsTypes,
  callParticipantsTypes,
  joinedAsTypes,
  collaborationTypes,
} from "../CardFiltersData/CardFiltersData";
import TeamsTimePicker from "../../../Shared/uicomponents/TeamsTimePicker";
import WindowContext from "../../../Shared/Context/Context";
import NativeTimePicker from "../../../Shared/uicomponents/NativeTimePicker";
import { CardWidget, WidgetPreview, CardLivePreview } from "../../../cards/Card/Card";
import { Dialog as KendoDialog } from "@progress/kendo-react-dialogs";
//import { AllWidgets } from "../../../pages/Dashboard/allwidgets";
import TeamsDatePicker from "../../../Shared/uicomponents/TeamsDatePicker";
import NativeDatePicker from "../../../Shared/uicomponents/NativeDatePicker";
import * as moment from "moment";
import DashboardApiService from "../../../../services/DashboardApiService";
import { ButtonGroup } from "reactstrap";
import { isNull } from "lodash";
import { monthsInQuarter } from "date-fns";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useSetState } from "react-use";


const SingleCardSettings = (props) => {
  const [dashboardId, setDashboardId] = useState(props.dashboardId);
  const [loaded, setLoaded] = useState(false);
  const [cardData, setCardData] = useState(props.cardData);
  const [filterData, setFilterData] = useState(null);
  const [submitButtonState, setSubmitButtonState] = useState(0);
  const [submitSaveAsButtonState, setSubmitSaveAsButtonState] = useState(0);
  const [cardNameInput, setCardNameInput] = useState("");
  const [cardName, setCardName] = useState("");
  const [date, setDate] = useState(1);
  const [time, setTime] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());//props.cardData.selectedEndDate
  const [endDate, setEndDate] = useState(new Date());//props.cardData.selectedEndDate
  const [teams, setTeams] = useState([]);
  const [teamsDisabled, setTeamsDisabled] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsDisabled, setGroupsDisabled] = useState(false);
  const [cardNameError, setCardNameError] = useState(false);
  const [agents, setAgents] = useState([]);
  const [agentsDisabled, setAgentsDisabled] = useState(false);
  const [callQueues, setCallQueues] = useState([]);
  const [allCallQueues, setallCallQueues] = useState(false);
  const [allGroups, setallGroups] = useState(false);
  const [allUsers, setallUsers] = useState(false);
  const [allTeams, setallTeams] = useState(false);
  const [allAgents, setallAgents] = useState(false);
  const [callQueuesDisabled, setCallQueuesDisabled] = useState(false);
  const [users, setUsers] = useState([]);
  const [dateItems, setDateItems] = useState({});
  const [timeItems, setTimeItems] = useState({});
  const [usersDisabled, setUsersDisabled] = useState(false);
  const [modalities, setModalities] = useState({});
  const [callQualities, setCallQualities] = useState({});
  const [sentiments, setSentiments] = useState({});
  const [reactions, setReactions] = useState({});
  const [msgParticipants, setmsgParticipants] = useState({});
  const [callParticipants, setcallParticipants] = useState({});
  const [messages, setMessages] = useState(false);
  const [joinedAs, setJoinedAs] = useState({});
  const [collaborationType, setCollaborationType] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterTypeId, setFilterTypeId] = useState(0);
  const [filterType, setFilterType] = useState(FilterTypes[0]);
  const [teamIsGroup, setTeamIsGroup] = useState(false);
  const [teamIsGroupDisabled, setTeamIsGroupDisabled] = useState(false);
  const [teamIsGroupList, setTeamIsGroupList] = useState(null);
  const [teamIsNotGroupList, setTeamIsNotGroupList] = useState(null);
  const [saveAs, setSaveAs] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [expandPeople, setExpandPeople] = useState(0);
  const [peopleMasKList, setPeopleMaskList] = useState([]);
  const [preview, setPreview] = useState(false);
  const [livePreview, setLivePreview] = useState(false);
  const [livePreviewData, setLivePreviewData] = useState(null);
  const [previewing, setPreviewing] = useState(false);
  const [allcards, setAllCards] = useState(props.cardList);
  const [isCardNameValid, setIsCardNameValid] = useState(true);
  const [_fromDateLabel, _setFromDateLabel] = useState();
  const [_toDateLabel, _setToDateLabel] = useState();
  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
  const [submitDemoButtonState, setSubmitDemoButtonState] = useState(0);
  const [enableAllJoyRideTips, setEnableAllJoyRideTips] = useState(false);

  const handleQuarter = (value) => {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3));
    var _fromDateLabel;
    if (value === 9) {
      _fromDateLabel = new Date(today.getFullYear(), quarter * 3, 1);
    }
    else if (value === 10) {
      _fromDateLabel = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    }

    if (value === 9 || value === 10) {
      let _toDateLabel = new Date(_fromDateLabel.getFullYear(), _fromDateLabel.getMonth() + 3, 0);

      _setFromDateLabel(_fromDateLabel.toLocaleString('en-US', {
        year: "numeric",
        month: "long",
        day: "numeric",
      }));
      _setToDateLabel(_toDateLabel.toLocaleString('en-US', {
        year: "numeric",
        month: "long",
        day: "numeric",
      }));
    }
  }

  const checkValid = (_filterType, _filterOption, _selectCount) => {
    if (_filterType === 0 && card_filters[cardData.cardFilterID].users !== 0) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    }
    else if (_filterType === 1 && card_filters[cardData.cardFilterID].teams !== 0) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    }
    else if (_filterType === 2 && card_filters[cardData.cardFilterID].groups !== 0) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    }
    else if (_filterType === 3 && card_filters[cardData.cardFilterID].callQueues !== 0) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    }
    else if (_filterType === 4 && card_filters[cardData.cardFilterID].agents !== 0) {
      if (_filterOption === 3) {
        return _selectCount === 2 ? true : false;
      } else {
        return _selectCount >= 1 ? true : false;
      }
    } else {
      return true;
    }
  };

  const setDisableState = (_filterType, _filterOption, _selectCount) => {
    let _shouldDisable = false;
    if (_filterOption === 3) {
      _shouldDisable = _selectCount === 2 ? true : false;
    }
    else if (_filterOption === 1) {
      _shouldDisable = _selectCount === 1 ? true : false;
    }

    switch (_filterType) {
      case 0:
        setUsersDisabled(_shouldDisable);
        break;
      case 1:
        setTeamsDisabled(_shouldDisable);
        break;
      case 2:
        setGroupsDisabled(_shouldDisable);
        break;
      case 3:
        setCallQueuesDisabled(_shouldDisable);
        break;
      case 4:
        setAgentsDisabled(_shouldDisable);
        break;
    }
  };

  const setStates = (_filterData, _cardData) => {
    //if (!isDemo) {
    setSubmitButtonState(0);

    setSubmitSaveAsButtonState(0);
    //}
    setExpandPeople(0);
    setPeopleMaskList([]);
    setFilterTypes([]);
    setFilterTypeId(0);
    setFilterType(FilterTypes[0]);
    setCardData(_cardData);
    let settingsData = {};
    let _isUpdate = false;
    if (_cardData && _cardData.cardID !== -1) {
      settingsData = _cardData;
      setIsUpdate(true);
      _isUpdate = true;
    } else {
      let modalitie = [0, 1, 2];
      if (cardData.cardWidgetID === 5) {
        modalitie = [0, 1];
      }

      settingsData = {
        name: _cardData.widgetName,
        runPeriod: 3,
        timePeriod: 2,
        selectedStartDate: moment().toDate(),
        selectedEndDate: moment().toDate(),
        selectedStartTime: "00:00",
        selectedEndTime: "23:59",
        groups: [],
        teams: [],
        teamIsGroup: false,
        users: [],
        agents: [],
        callQueues: [],
        modalities: modalitie,
        sentiment: [0, 1, 2],
        callQuality: [0, 1, 2],
        reactions: [0, 1, 2, 3, 4, 5, 6],
        msgParticipants: [0, 1],
        callParticipants: [0, 1],
        joinedAs: [0, 1],
        collaborationType: [0, 1],
        messages: true,
      };
    }

    let _filterTypes = JSON.parse(JSON.stringify(FilterTypes));
    if (card_filters[_cardData.cardFilterID].users !== 0) {
      _filterTypes[0].isApplicable = true;
    }
    if (card_filters[_cardData.cardFilterID].teams !== 0) {
      _filterTypes[1].isApplicable = true;
    }
    if (card_filters[_cardData.cardFilterID].groups !== 0) {
      _filterTypes[2].isApplicable = true;
    }
    if (card_filters[_cardData.cardFilterID].callQueues !== 0) {
      _filterTypes[3].isApplicable = true;
    }
    if (card_filters[_cardData.cardFilterID].agents !== 0) {
      _filterTypes[4].isApplicable = true;
    }

    setFilterTypes(_filterTypes);
    let _selectedFilterTypeId = 0;

    if (settingsData.users && settingsData.users.length > 0) {
      _selectedFilterTypeId = 0;
    } else if (settingsData.teams && settingsData.teams.length > 0) {
      _selectedFilterTypeId = 1;
    } else if (settingsData.groups && settingsData.groups.length > 0) {
      _selectedFilterTypeId = 2;
    } else if (settingsData.callQueues && settingsData.callQueues.length > 0) {
      _selectedFilterTypeId = 3;
    } else if (settingsData.agents && settingsData.agents.length > 0) {
      _selectedFilterTypeId = 4;
    } else {
      _selectedFilterTypeId = _filterTypes.filter((ft) => ft.isApplicable === true).length > 0
        ? _filterTypes.filter((ft) => ft.isApplicable === true)[0].value : 0;
    }
    setFilterTypeId(_selectedFilterTypeId);
    setFilterType(FilterTypes[_selectedFilterTypeId]);

    setTeamIsGroup(settingsData.teamIsGroup);
    setCardNameInput(settingsData.name);
    setCardName(settingsData.name);
    setDate(settingsData.runPeriod);
    setTime(settingsData.timePeriod);
    setStartTime(
      settingsData.selectedStartTime != ""
        ? settingsData.selectedStartTime
        : "00:00"
    );
    setEndTime(
      settingsData.selectedEndTime != ""
        ? settingsData.selectedEndTime
        : "23:59"
    );
    setStartDate(settingsData.selectedStartDate);
    setEndDate(settingsData.selectedEndDate);
    let _teams = _filterData.teamFilter.items.filter((d) =>
      settingsData.teams.includes(d.value)
    );
    if (_isUpdate && settingsData.expiredFilterType === 1 && settingsData.expiredFilterValues) {
      _teams = [..._teams, ...settingsData.expiredFilterValues];
    }
    _teams = settingsData.teamIsGroup === false ? _teams.filter(el => !el.teamIsGroup) : _teams.filter(el => el.teamIsGroup);
    setTeams(_teams);

    //Code to keep save button enabled when switching from one card to other during editing
    if (settingsData.name === null && settingsData.name.trim().length > 0) {
      setIsCardNameValid(false);
    }
    else {
      setIsCardNameValid(true);
    }
    let isAllTeamAreGroup = _teams.filter(el => !el.teamIsGroup).length === 0;
    if (isAllTeamAreGroup) {
      setTeamIsGroupDisabled(false);
    } else {
      setTeamIsGroupDisabled(true);
      setTeamIsGroup(false);
    }

    let _groups = _filterData.groupFilter.items.filter((d) =>
      settingsData.groups.includes(d.value)
    );
    if (_isUpdate && settingsData.expiredFilterType === 2 && settingsData.expiredFilterValues) {
      _groups = [..._groups, ...settingsData.expiredFilterValues];
    }
    setGroups(_groups);

    let _callQueues = _filterData.callQueueFilter.items.filter((d) =>
      settingsData.callQueues.includes(d.value)
    );
    if (_isUpdate && settingsData.expiredFilterType === 3 && settingsData.expiredFilterValues) {
      _callQueues = [..._callQueues, ...settingsData.expiredFilterValues];
    }
    setCallQueues(_callQueues);

    let _agents = _filterData.agentFilter.items.filter((d) =>
      settingsData.agents.includes(d.value)
    );
    if (_isUpdate && settingsData.expiredFilterType === 3 && settingsData.expiredFilterValues) {
      _agents = [..._agents, ...settingsData.expiredFilterValues];
    }
    setAgents(_agents);

    let _users = _filterData.userFilter.items.filter((d) =>
      settingsData.users.includes(d.value)
    );
    if (_isUpdate && settingsData.expiredFilterType === 0 && settingsData.expiredFilterValues) {
      _users = [..._users, ...settingsData.expiredFilterValues];
    }
    setUsers(_users);

    setDateItems(_filterData.dateFilter.items);
    setTimeItems(_filterData.timeFilter.items);
    let m = {};
    settingsData.modalities.map((d) => (m[d] = true));
    setModalities(m);
    let cq = {};
    settingsData.callQuality.map((d) => (cq[d] = true));
    setCallQualities(cq);
    let r = {};
    settingsData.reactions.map((d) => (r[d] = true));
    setReactions(r);
    let s = {};
    settingsData.sentiment.map((d) => (s[d] = true));
    setSentiments(s);
    let mp = {};
    settingsData.msgParticipants.map((d) => (mp[d] = true));
    setmsgParticipants(mp);
    let cp = {};
    settingsData.callParticipants.map((d) => (cp[d] = true));
    setcallParticipants(cp);
    let ja = {};
    settingsData.joinedAs.map((d) => (ja[d] = true));
    setJoinedAs(ja);
    let ct = {};
    settingsData.collaborationType.map((d) => (ct[d] = true));
    setCollaborationType(ct);
    setMessages(settingsData.messages);

    let _selectCount = 0, _filterType = -1, _filterOption = 0;
    if (_filterTypes.filter((ft) => ft.isApplicable === true).length > 0) {
      if (_selectedFilterTypeId === 0) {
        _filterType = 0;
        _filterOption = card_filters[_cardData.cardFilterID].users;
        _selectCount = _filterData.userFilter.items.filter((d) =>
          settingsData.users.includes(d.value)
        ).length;
      } else if (_selectedFilterTypeId === 1) {
        _filterType = 1;
        _filterOption = card_filters[_cardData.cardFilterID].teams;
        // _selectCount = _filterData.teamFilter.items.filter((d) =>
        //   settingsData.teams.includes(d.value)
        // ).length;
        /*_selectCount = settingsData.teams.filter((d) =>
            _filterData.teamFilter.items.value = d 
        ).length;*/
        let arr1 = settingsData.teams.filter((d) => _filterData.teamFilter.items.value = d);
        if (settingsData.expiredFilterValues) {
          let arr2 = settingsData.expiredFilterValues
            .filter((d) => d.value)
            .filter((d) => d.isExpired === true);
          let arr3 = arr1.filter((x) => !arr2.find(y => y.value === x));
          _selectCount = arr3.length;
        }
        else {
          _selectCount = arr1.length;
        }

      } else if (_selectedFilterTypeId === 2) {
        _filterType = 2;
        _filterOption = card_filters[_cardData.cardFilterID].groups;
        _selectCount = _filterData.groupFilter.items.filter((d) =>
          settingsData.groups.includes(d.value)
        ).length;
      } else if (_selectedFilterTypeId === 3) {
        _filterType = 3;
        _filterOption = card_filters[_cardData.cardFilterID].callQueues;
        _selectCount = _filterData.callQueueFilter.items.filter((d) =>
          settingsData.callQueues.includes(d.value)
        ).length;
      } else if (_selectedFilterTypeId === 4) {
        _filterType = 4;
        _filterOption = card_filters[_cardData.cardFilterID].agents;
        _selectCount = _filterData.agentFilter.items.filter((d) =>
          settingsData.agents.includes(d.value)
        ).length;
      }
    } else {
      _filterType = -1;
    }
    setIsValid(checkValid(_filterType, _filterOption, _selectCount));
    setDisableState(_filterType, _filterOption, _selectCount);

    setLoaded(true);
    setTimeout(function () {
      document
        .querySelectorAll(".k-dialog .ui-input__input")
        .forEach((item) => {
          item.addEventListener("keydown", function (e) {
            if (e.keyCode === 32) {
              e.stopPropagation();
            }
          });
        });
    }, 1000);
  };
  useEffect(() => {

    var list = [];
    let data = { ...props.userFiltersData.teamFilter };
    for (let i = 0; i < data.items.length; i++) {
      if (data.items[i].teamIsGroup === false)
        list.push(data.items[i]);
    }
    setTeamIsNotGroupList(list);
  }, [])

  useEffect(() => {
    setLoaded(false);
    let data = props.userFiltersData;
    setFilterData(data);
    setStates(data, props.cardData);
  }, []);

  useEffect(() => {
    if (isDemoMode === "true") {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }
  }, []);

  useEffect(() => {
    //assign default stored values
    if (filterData && props.cardData) {
      setLoaded(false);
      setStates(filterData, props.cardData);
    }
  }, [props.cardData]);

  const handleTeamChange = (value, filterOption) => {
    var isAllTeamAreGroup = value.filter(el => !el.teamIsGroup).length === 0;
    var valExpired = value.filter(x => x.isExpired === true);
    if (isAllTeamAreGroup) {
      setTeamIsGroupDisabled(false);
    } else {
      setTeamIsGroupDisabled(true);
      setTeamIsGroup(false);
    }

    if (filterOption !== 1) {
      setTeams(value);
    } else {
      setTeams(value);
    }
    if (filterOption === 3) {
      setTeamsDisabled(value.length - valExpired.length === 2 ? true : false);
    }
    else if (filterOption === 1) {
      setTeamsDisabled(value.length - valExpired.length > 0 ? true : false);
    }
    setIsValid(checkValid(1, filterOption, filterOption !== 1 ? value.length - valExpired.length : 1));
    //if (!isDemo) {
    setSubmitButtonState(0);
    setSubmitSaveAsButtonState(0);
  };

  const handleCallQueuesChange = (value, filterOption) => {
    if (filterOption !== 1) {
      setCallQueues(value);
    } else {
      setCallQueues([value]);
    }

    if (filterOption === 3) {
      setCallQueuesDisabled(value.length === 2 ? true : false);
    }
    else if (filterOption === 1) {
      setCallQueuesDisabled(value.length === 1 ? true : false);
    }
    setIsValid(checkValid(3, filterOption, filterOption !== 1 ? value.length : 1));
    //if (!isDemo) {
    setSubmitButtonState(0);
    setSubmitSaveAsButtonState(0);
  }

  const handleAgentsChange = (value, filterOption) => {
    if (filterOption !== 1) {
      setAgents(value);
    } else {
      setAgents([value]);
    }

    if (filterOption === 3) {
      setAgentsDisabled(value.length === 2 ? true : false);
    }
    else if (filterOption === 1) {
      setAgentsDisabled(value.length === 1 ? true : false);
    }
    setIsValid(checkValid(4, filterOption, filterOption !== 1 ? value.length : 1));
    setSubmitButtonState(0);
    setSubmitSaveAsButtonState(0);
  }

  const handleGroupChange = (value, filterOption) => {
    if (filterOption !== 1) {
      setGroups(value);
    } else {
      setGroups([value]);
    }

    if (filterOption === 3) {
      setGroupsDisabled(value.length === 2 ? true : false);
    }
    else if (filterOption === 1) {
      setGroupsDisabled(value.length === 1 ? true : false);
    }
    setIsValid(checkValid(2, filterOption, filterOption !== 1 ? value.length : 1));
    //if (!isDemo) {
    setSubmitButtonState(0);
    setSubmitSaveAsButtonState(0);
  };

  const handleUserChange = (value, filterOption) => {
    // if (filterOption !== 1) {
    //   setUsers(value);
    // } else {
    //   setUsers([value]);
    // }
    setUsers(value);

    if (filterOption === 3) {
      setUsersDisabled(value.length === 2 ? true : false);
    }
    if (filterOption === 1) {
      setUsersDisabled(value.length === 1 ? true : false);
    }
    //if (!isDemo) {
    setIsValid(checkValid(0, filterOption, filterOption !== 1 ? value.length : 1));
    setSubmitButtonState(0);
    setSubmitSaveAsButtonState(0);
    //}
  };

  const handleSubmit = (dataItem) => {

    // if (isDemo) {
    //   setSubmitDemoButtonState(1);
    //   setTimeout(function () {
    //     setSubmitDemoButtonState(2)
    //   }, 1000);
    // }
    // else {
    if (saveAs)
      setSubmitSaveAsButtonState(1);
    else
      setSubmitButtonState(1);


    let settingsData = {
      cardID: cardData.cardID,
      dashboardId: dashboardId,
      name: cardNameInput,
      cardWidgetID: cardData.cardWidgetID,
      cardFilterID: cardData.cardFilterID,
      cardViewTypeID: cardData.cardViewTypeID,
      runPeriod: date,
      timePeriod: time,
      selectedStartDate: date === 5 ? (new Date(startDate)).toDateString('yyyy-MM-dd HH: mm:ss.fff') : null,//new Date(cardData.selectedStartDate).toDateString(),//
      selectedEndDate: date === 5 ? (new Date(endDate)).toDateString('yyyy-MM-dd HH: mm:ss.fff') : null,//new Date(cardData.selectedEndDate).toDateString(),//
      selectedStartTime: time === 2 ? startTime : "",
      selectedEndTime: time === 2 ? endTime : "",
      TeamFilter:
        filterTypeId === 1 ? (isUpdate || saveAs ? teams.filter(f => f.isExpired != true).map((d) => d.value).toString() : teams.map((d) => d.value).toString()) : "",
      teamIsGroup: filterTypeId === 1 ? teamIsGroup : false,
      GroupFilter:
        filterTypeId === 2 ? (isUpdate || saveAs ? groups.filter(f => f.isExpired != true).map((d) => d.value).toString() : groups.map((d) => d.value).toString()) : "",
      callQueueFilter:
        filterTypeId === 3 ? (isUpdate || saveAs ? callQueues.filter(f => f.isExpired != true).map((d) => d.value).toString() : callQueues.map((d) => d.value).toString()) : "",
      agentFilter:
        filterTypeId === 4 ? (isUpdate || saveAs ? agents.filter(f => f.isExpired != true).map((d) => d.value).toString() : agents.map((d) => d.value).toString()) : "",
      userFilter:
        filterTypeId === 0 ? (isUpdate || saveAs ? users.filter(f => f.isExpired != true).map((d) => d.value).toString() : users.map((d) => d.value).toString()) : "",
      callQualityFilter: card_filters[cardData.cardFilterID].callQuality === 1 ? Object.keys(callQualities)
        .filter((i) => callQualities[i] === true)
        .toString() : "",
      sentimentFilter: card_filters[cardData.cardFilterID].sentiment === 1 ? Object.keys(sentiments)
        .filter((i) => sentiments[i] === true)
        .toString() : "",
      reactionFilter: card_filters[cardData.cardFilterID].reactions === 1 ? Object.keys(reactions)
        .filter((i) => reactions[i] === true)
        .toString() : "",
      msgParticipantsFilter: card_filters[cardData.cardFilterID].msgParticipation == 1 ? Object.keys(msgParticipants)
        .filter((i) => msgParticipants[i] == true)
        .toString() : "",
      callParticipantsFilter: card_filters[cardData.cardFilterID].callParticipation == 1 ? Object.keys(callParticipants)
        .filter((i) => callParticipants[i] == true)
        .toString() : "",
      modalitiesFilter: card_filters[cardData.cardFilterID].modalities === 1 ? Object.keys(modalities)
        .filter((i) => modalities[i] === true)
        .toString() : "",
      messagesFilter: card_filters[cardData.cardFilterID].messages === 1,
      joinedAsFilter: card_filters[cardData.cardFilterID].joinedAs == 1 ? Object.keys(joinedAs)
        .filter((i) => joinedAs[i] == true)
        .toString() : "",
      collaborationTypeFilter: card_filters[cardData.cardFilterID].collaborationType == 1 ? Object.keys(collaborationType)
        .filter((i) => collaborationType[i] == true)
        .toString() : "",
      xPosition: 0,
      yPosition: 0,
      // isDemo: isDemo,
    };
    if (isDemo) {
      settingsData.userFilter = "";
      settingsData.agentFilter = "";
      settingsData.TeamFilter = "";
      settingsData.GroupFilter = "";
      settingsData.callQueueFilter = "";
      settingsData.msgParticipantsFilter = "";
      settingsData.callParticipantsFilter = "";
    }
    if (isUpdate && saveAs === 0) {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.GetDashboardCards(dashboardId, token).then((response) => {


          let count = response.filter(
            (item) => item.name === settingsData.Name && item.cardID !== settingsData.cardId
          ).length;
          if (count == 0) {
            FiltersApiService.UpdateCardWithFilters(settingsData, token)
              .then((response) => {
                if (response) {
                  if (response === "/dashboards") {
                    window.location.replace("/dashboards");
                  }
                  setSubmitButtonState(2);
                  if (filterTypeId === 1) {
                    setTeams(teams.filter(f => f.isExpired != true));
                  }
                  else if (filterTypeId === 2) {
                    setGroups(groups.filter(f => f.isExpired != true));
                  }
                  else if (filterTypeId === 0) {
                    setUsers(users.filter(f => f.isExpired != true))
                  }
                  else if (filterTypeId === 3) {
                    setCallQueues(callQueues.filter(f => f.isExpired != true))
                  }
                  else if (filterTypeId === 4) {
                    setAgents(agents.filter(f => f.isExpired != true))
                  }
                  props.submitHandler(settingsData);
                } else {
                  setSubmitButtonState(3);
                }
                setCardNameError(false);

              })
              .finally(() => {
                /*setTimeout(function () {
                  setSubmitButtonState(0);
                }, 1000);*/
              });

          } else {
            //if (!isDemo) { 
            setSubmitButtonState(0); //}
            setCardNameError(true);
          }
        });
      });
    } else {
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.GetDashboardCards(dashboardId, token).then((response) => {

          var i = 0;
          var count = -1;
          var generateCardName = true;
          if (isUpdate) {
            count = response.filter(
              (item) => item.name === (settingsData.name)
            ).length;
            if (count === 0)
              generateCardName = false;
            else
              count = -1;
            while (count !== 0) {
              i = i + 1;
              count = response.filter(
                (item) => item.name === (settingsData.name + " (" + i + ")") && item.cardID !== settingsData.cardID
              ).length;
            }
          }
          else {
            count = response.filter(
              (item) => item.name === settingsData.name
            ).length;
          }
          if (count == 0) {
            if (isUpdate && generateCardName) {
              settingsData.name = settingsData.name + " (" + i + ")";

            }
            setCardName(settingsData.name);
            FiltersApiService.AddCardWithFilters(settingsData, token)
              .then((response) => {
                if (response.data === "/dashboards") {
                  window.location.replace("/dashboards");
                }
                if (response.status === 200 && response.data !== -1) {
                  if (isUpdate) { setSubmitSaveAsButtonState(2); }
                  else { setSubmitButtonState(2); }
                  settingsData.cardID = response.data;
                  if (filterTypeId === 1) {
                    setTeams(teams.filter(f => f.isExpired != true));
                  }
                  else if (filterTypeId === 2) {
                    setGroups(groups.filter(f => f.isExpired != true));
                  }
                  else if (filterTypeId === 0) {
                    setUsers(users.filter(f => f.isExpired != true))
                  }
                  else if (filterTypeId === 3) {
                    setCallQueues(callQueues.filter(f => f.isExpired != true))
                  }
                  else if (filterTypeId === 4) {
                    setAgents(agents.filter(f => f.isExpired != true))
                  }

                  props.submitHandler(settingsData);

                } else {
                  if (isUpdate) { setSubmitSaveAsButtonState(3); }
                  else { setSubmitButtonState(3); }
                }
              })
              .finally(() => {
                /*setTimeout(function () {
                  setSubmitButtonState(0);
                  // if(isUpdate)
                  //{ setSubmitSaveAsButtonState(0);}
                  // else 
                  //{ setSubmitButtonState(0);}
                }, 1000);*/
              });
          }
          else {
            setCardNameError(true);
            //if (!isDemo) { 
            if (isUpdate) { setSubmitSaveAsButtonState(0); }
            else { setSubmitButtonState(0); }// }
          }
        });
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  };

  const onExpandCancel = () => {
    setExpandPeople(0);
  };

  const onExpandConfirm = () => {
    let currentSelection = users;
    currentSelection.splice(
      currentSelection.findIndex((cs) => cs.value === expandPeople),
      1
    );
    if (expandPeople === -1) {
      filterData.userFilter.items
        .filter((f) => f.key > 0)
        .forEach(function (obj, v) {
          if (currentSelection.findIndex((f) => f.value === obj.value) === -1) {
            currentSelection.push(obj);
          }
        });
      setPeopleMaskList([-1, -2]);
    } else if (expandPeople === -2) {
      filterData.userFilter.items
        .filter((f) => f.key > 0 && f.isDirectReportee === true)
        .forEach(function (obj, v) {
          if (currentSelection.findIndex((f) => f.value === obj.value) === -1) {
            currentSelection.push(obj);
          }
        });
      setPeopleMaskList([-2]);
    }
    setUsers(currentSelection);
    setExpandPeople(0);
  };

  const handleCardNameInput = (value) => {
    setCardNameInput(value);
    //if (!isDemo) { 
    setSubmitButtonState(0); //}
    if (value === null || value.trim().length === 0) {
      setIsCardNameValid(false);
      // console.log("name invalid");
    }
    else {
      setIsCardNameValid(true);
      //console.log("name valid");
    }
  };

  const onCancel = (event) => {
    setPreview(true)
    event.preventDefault();
  };



  const onLivePreviewClose = () => {
    setPreviewing(false);
    setLivePreview(false);
    setLivePreviewData(null);
  }

  const handleAllcallQueues = (checked) => {
    if (checked) {
      let data = filterData.callQueueFilter.items.filter((e) => !e.disabled && filterData.callQueueFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.callQueueFilter.items.filter((e) => e.disabled));
      setCallQueues(data);
      handleCallQueuesChange(
        data,
        card_filters[cardData.cardFilterID].callQueues
      );
    } else {
      let data = filterData.callQueueFilter.items.filter((e) => e.disabled);
      handleCallQueuesChange(
        data,
        card_filters[cardData.cardFilterID].callQueues
      );
      setCallQueues(data);
    }
    setallCallQueues(checked);
  }

  const handleAllGroups = (checked) => {
    if (checked) {
      let data = filterData.groupFilter.items.filter((e) => !e.disabled && filterData.groupFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.groupFilter.items.filter((e) => e.disabled));
      setGroups(data);
      handleGroupChange(
        data,
        card_filters[cardData.cardFilterID].callQueues
      );
    } else {
      let data = filterData.callQueueFilter.items.filter((e) => e.disabled);
      handleGroupChange(
        data,
        card_filters[cardData.cardFilterID].callQueues
      );
      setGroups(data);
    }
    setallGroups(checked);
  }

  const handleAllUsers = (checked) => {
    if (checked) {
      let data = filterData.userFilter.items.filter((e) => !e.disabled && filterData.userFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.userFilter.items.filter((e) => e.disabled));
      setUsers(data);
      handleUserChange(
        data,
        card_filters[cardData.cardFilterID].users
      );
    } else {
      let data = filterData.callQueueFilter.items.filter((e) => e.disabled);
      handleUserChange(
        data,
        card_filters[cardData.cardFilterID].users
      );
      setUsers(data);
    }
    setallUsers(checked);
  }

  const handleAllTeams = (checked) => {
    if (checked) {
      let data = filterData.teamFilter.items.filter((e) => !e.disabled && filterData.teamFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.teamFilter.items.filter((e) => e.disabled));
      setTeams(data);
      handleTeamChange(
        data,
        card_filters[cardData.cardFilterID].teams
      );
    } else {
      let data = filterData.teamFilter.items.filter((e) => e.disabled);
      handleTeamChange(
        data,
        card_filters[cardData.cardFilterID].teams
      );
      setTeams(data);
    }
    setallTeams(checked);
  }

  const handleAllAgents = (checked) => {
    if (checked) {
      let data = filterData.agentFilter.items.filter((e) => !e.disabled && filterData.agentFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.agentFilter.items.filter((e) => e.disabled));
      setAgents(data);
      handleAgentsChange(
        data,
        card_filters[cardData.cardFilterID].agents
      );
    } else {
      let data = filterData.agentFilter.items.filter((e) => e.disabled);
      handleAgentsChange(
        data,
        card_filters[cardData.cardFilterID].agents
      );
      setAgents(data);
    }
    setallAgents(checked);
  }

  const onTeamIsGroup = (checked) => {
    setTeamIsGroup(checked);
    if (checked) {
      var list = [];
      let data = { ...props.userFiltersData.teamFilter };
      for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].teamIsGroup === true)
          list.push(data.items[i]);

      }
      setTeamIsGroupList(list);

    }
  }

  const onLivePreviewClick = (e) => {
    setSaveAs(0);
    let widgetData = allcards.filter((f) => f.cardWidgetID === props.cardData.cardWidgetID);
    let viewID = widgetData[0].cardViewTypeID;
    e.preventDefault();
    e.stopPropagation();
    setPreviewing(true);
    let settingsData = {
      cardID: cardData.cardID,
      dashboardId: dashboardId,
      name: cardNameInput,
      cardWidgetID: cardData.cardWidgetID,
      cardFilterID: cardData.cardFilterID,
      cardViewTypeID: viewID,
      runPeriod: date,
      timePeriod: time,
      selectedStartDate: date == 5 ? (new Date(startDate)).toDateString('yyyy-MM-dd HH: mm:ss.fff') : null,//new Date(cardData.selectedStartDate),
      selectedEndDate: date == 5 ? (new Date(endDate)).toDateString('yyyy-MM-dd HH: mm:ss.fff') : null,//new Date(cardData.selectedEndDate),
      selectedStartTime: time === 2 ? startTime : "",
      selectedEndTime: time === 2 ? endTime : "",
      TeamFilter:
        filterTypeId === 1 ? (isUpdate ? teams.filter(f => f.isExpired != true).map((d) => d.value).toString() : teams.map((d) => d.value).toString()) : "",
      teamIsGroup: filterTypeId === 1 ? teamIsGroup : false,
      GroupFilter:
        filterTypeId === 2 ? (isUpdate ? groups.filter(f => f.isExpired != true).map((d) => d.value).toString() : groups.map((d) => d.value).toString()) : "",
      callQueueFilter:
        filterTypeId === 3 ? (isUpdate ? callQueues.filter(f => f.isExpired != true).map((d) => d.value).toString() : callQueues.map((d) => d.value).toString()) : "",
      agentFilter:
        filterTypeId === 4 ? (isUpdate ? agents.filter(f => f.isExpired != true).map((d) => d.value).toString() : agents.map((d) => d.value).toString()) : "",
      userFilter:
        filterTypeId === 0 ? (isUpdate ? users.filter(f => f.isExpired != true).map((d) => d.value).toString() : users.map((d) => d.value).toString()) : "",
      callQualityFilter: card_filters[cardData.cardFilterID].callQuality === 1 ? Object.keys(callQualities)
        .filter((i) => callQualities[i] === true)
        .toString() : "",
      sentimentFilter: card_filters[cardData.cardFilterID].sentiment === 1 ? Object.keys(sentiments)
        .filter((i) => sentiments[i] === true)
        .toString() : "",
      msgParticipantsFilter: card_filters[cardData.cardFilterID].msgParticipation == 1 ? Object.keys(msgParticipants)
        .filter((i) => msgParticipants[i] == true)
        .toString() : "",
      callParticipantsFilter: card_filters[cardData.cardFilterID].callParticipation == 1 ? Object.keys(callParticipants)
        .filter((i) => callParticipants[i] == true)
        .toString() : "",
      reactionFilter: card_filters[cardData.cardFilterID].reactions === 1 ? Object.keys(reactions)
        .filter((i) => reactions[i] === true)
        .toString() : "",
      modalitiesFilter: card_filters[cardData.cardFilterID].modalities === 1 ? Object.keys(modalities)
        .filter((i) => modalities[i] === true)
        .toString() : "",
      messagesFilter: card_filters[cardData.cardFilterID].messages === 1,
      joinedAsFilter: card_filters[cardData.cardFilterID].joinedAs == 1 ? Object.keys(joinedAs)
        .filter((i) => joinedAs[i] == true)
        .toString() : "",
      collaborationTypeFilter: card_filters[cardData.cardFilterID].collaborationType == 1 ? Object.keys(collaborationType)
        .filter((i) => collaborationType[i] == true)
        .toString() : "",
      xPosition: 0,
      yPosition: 0,
      // isDemo: isDemo,
    };
    AuthHelper.getAccessToken(function (token) {
      FiltersApiService.GetCardLivePreview(-1, props.selectedDate, settingsData, token)
        .then((response) => {
          if (response) {
            setLivePreviewData({ data: response, cardWidgetID: settingsData.cardWidgetID, name: settingsData.name, viewTypeID: viewID })
            setLivePreview(true);
          } else {
            setLivePreviewData({ data: response, cardWidgetID: settingsData.cardWidgetID, name: settingsData.name, viewTypeID: viewID })
            setLivePreview(true);
          }
        });
    });
  }

  const [{ run, steps, stepIndex, HSAClick }, setState] = useSetState({
    stepIndex: 0,
    HSAClick: false,
    run: true,
    steps: [
      {
        title: "Card Name",
        content: "Give default/custome name.",
        locale: { skip: "skip" },
        placement: "bottom",
        target: "#addCardName",
        spotlightClicks: true,
        disableOverlayClose: true,
        disableBeacon: true,
      },
      {
        title: "Select Period",
        content: "Data shown in card for selected period only.",
        locale: { skip: false },
        placement: "right",
        target: "#addCardPeriod",
        spotlightClicks: true,
        disableOverlayClose: true,
        disableBeacon: true,
        disableOverlay: true
      },
      {
        title: "Time range",
        content: "Data shown in card for selected period in selected time.",
        locale: { skip: false },
        placement: "left",
        target: "#addCardTime",
        spotlightClicks: true,
        disableOverlayClose: true,
        disableBeacon: true,
      },
      {
        title: "Choose Organisation",
        content: "Data shown in card for selected Oraganisation only.",
        locale: { skip: false },
        placement: "right",
        target: "#addCardOrg",
        spotlightClicks: true,
        disableOverlayClose: true,
        disableBeacon: true,
      },
      {
        title: "Select/Input Team",
        content: "JData shown in card for selected teams only.",
        locale: { skip: false },
        placement: "left",
        target: "#addCardTeam",
        spotlightClicks: true,
        disableOverlayClose: true,
        disableBeacon: true,
        disableOverlay: true
      },
      {
        content: "All set for your first card.",
        locale: { skip: false, last: false },
        placement: "bottom",
        target: "#addCardSave",
        spotlightClicks: true,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,

      },
    ]
  })

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      // setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      if (HSAClick) {
        if (index === 5) {
          setTimeout(() => {
            setState({ run: false })
          }, 400)
        }
      }
      else if (index === 6 && action === ACTIONS.PREV) {
        //document.querySelector('tbody').lastChild.lastChild.firstChild.firstChild.setAttribute('id', 'testId');
        setState({
          run: false,
          HSAClick: false,
          stepIndex: nextStepIndex
        })
        setTimeout(() => {
          setState({ run: true })
        }, 400)
      } else {
        // Update state to advance the tour
        setState({
          HSAClick: false,
          stepIndex: nextStepIndex
        })
      }
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log("hit skip")
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false });
    }
  };

  const introClickHit = () => {

    setState({ run: true })
    if (stepIndex === 5) {
      console.log("hit finish")
      setState({
        run: stepIndex === 5 ? false : run,
        HSAClick: !HSAClick,
        stepIndex: stepIndex
      })
    }
  }

  return (
    <WindowContext.Consumer>
      {(context) =>
        loaded && cardData ? (
          <>
            {isDemo && enableAllJoyRideTips && <ReactJoyride
              continuous
              callback={handleJoyrideCallback}
              run={run}
              steps={steps}
              hideCloseButton
              scrollToFirstStep
              showSkipButton
              stepIndex={stepIndex}
              styles={{
                options: {

                  primaryColor: 'rgb(98, 100, 167)',
                  zIndex: 2000,
                }
              }}
            />}
            <Form
              className={`h-100 formCardSettings ${isUpdate ? ' isUpdate' : ''}`}
              onSubmit={(dataItem) => handleSubmit(dataItem)}
              styles={{ fontSize: "0.9rem" }}
            >
              <div style={{ height: '100%', overflowY: 'auto', marginBottom: '0' }}>
                <Segment className="shadow-none pt-0 border-bottom" styles={{ width: "100%", marginBottom: "1rem" }}>
                  <FormInput
                    id="addCardName"
                    label="Card Name"
                    name="cardName"
                    required
                    inline
                    fluid
                    defaultValue={cardName}
                    value={cardNameInput}
                    onChange={(e, { name, value }) => {
                      handleCardNameInput(value);
                      setCardNameError(false);
                    }}
                    className="mb-3"
                    maxLength="50"
                  />
                  {cardNameError ? <FormLabel className="text-danger form-text ">Card name in use.Enter new name.</FormLabel> : null}
                  <div className="dropdownSection mb-3" style={{ minHeight: "100px" }}>
                    {card_filters[cardData.cardFilterID].date === 1 ? (
                      <FormDropdown
                        id="addCardPeriod"
                        fluid
                        inline
                        label="Period"
                        items={dateItems}
                        defaultValue={
                          dateItems[
                          dateItems
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(date) === -1
                            ? 0
                            : dateItems
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(date)
                          ]
                        }
                        value={
                          dateItems[
                          dateItems
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(date) === -1
                            ? 0
                            : dateItems
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(date)
                          ]
                        }
                        onChange={(e, { value }) => {
                          setDate(value.value);
                          value.value == 5 ? setStartDate(new Date()) : setStartDate(null);
                          value.value == 5 ? setEndDate(new Date()) : setEndDate(null);

                          setSubmitSaveAsButtonState(0);
                          setSubmitButtonState(0);
                        }}

                      />
                    ) : (
                      <>
                      </>
                    )}
                    {card_filters[cardData.cardFilterID].time === 1 && (
                      <>
                        <FormDropdown
                          id="addCardTime"
                          fluid
                          inline
                          label="Time"
                          items={timeItems}
                          disabled={(filterTypeId === 1)}

                          defaultValue={
                            timeItems[
                            timeItems
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(time) === -1
                              ? 0
                              : timeItems
                                .map(function (e) {
                                  return e.value;
                                })
                                .indexOf(time)
                            ]
                          }
                          value={
                            timeItems[
                            timeItems
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(time) === -1
                              ? 0
                              : timeItems
                                .map(function (e) {
                                  return e.value;
                                })
                                .indexOf(time)
                            ]
                          }
                          onChange={(e, { value }) => {
                            setTime(value.value);
                            setSubmitSaveAsButtonState(0);
                            setSubmitButtonState(0); //}
                          }}
                        />
                      </>
                    )}

                    {date === 5 ? (
                      <>
                        <div className="custom-time-container">
                          <div>
                            <lable>Start Date</lable>
                            {
                              context.teams.hostClientType.web ||
                                context.teams.hostClientType.desktop ? (
                                <TeamsDatePicker
                                  required={true}
                                  value={startDate}
                                  onChange={(value) => {
                                    setStartDate(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0); //}
                                  }}
                                />
                              ) : (
                                <NativeDatePicker
                                  id="startDate"
                                  value={startDate}
                                  onChange={(value) => {
                                    setStartDate(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0); //}
                                  }}
                                  required={true}
                                />
                              )
                            }
                          </div>
                          <div>
                            <lable>End Date</lable>
                            {
                              context.teams.hostClientType.web ||
                                context.teams.hostClientType.desktop ? (
                                <TeamsDatePicker
                                  required={true}
                                  minDate={startDate > endDate ? endDate : startDate}
                                  value={endDate}
                                  onChange={(value) => {
                                    setEndDate(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0); //}
                                  }}
                                />
                              ) : (
                                <NativeDatePicker
                                  id="endDate"
                                  minDate={startDate > endDate ? endDate : startDate}
                                  value={endDate}
                                  onChange={(value) => {
                                    setEndDate(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0); //}
                                  }}
                                  required={true}
                                />
                              )
                              // <FormField
                              //   className="timePicker"
                              //   control = {{ as: Input, value: endTime, type: 'time',
                              //     onChange: (e)=>{setEndTime(e.target.value)}
                              //   }}
                              // />
                            }
                          </div>
                        </div>
                      </>
                    ) : (date === 9 || date === 10 ? <FormLabel className="form-text">
                      {handleQuarter(date)}
                      <span className="text-secondary">Selected Quarter is</span> {_fromDateLabel} <span className="text-secondary">to</span> {_toDateLabel}</FormLabel> :
                      <div></div>)
                    }
                    {time === 2 ? (
                      <>
                        <div className="custom-time-container">
                          <div>
                            <lable>Start Time</lable>
                            {
                              context.teams.hostClientType.web ||
                                context.teams.hostClientType.desktop ? (
                                <TeamsTimePicker
                                  required={true}
                                  value={startTime}
                                  onChange={(value) => {
                                    setStartTime(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0);// }
                                  }}
                                />
                              ) : (
                                <NativeTimePicker
                                  id="startTime"
                                  value={startTime}
                                  onChange={(value) => {
                                    setStartTime(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0); //};
                                  }}
                                  required={true}
                                />
                              )
                              // <FormField
                              //   className="timePicker"
                              //   control = {{ as: Input, value: startTime, type: 'time',
                              //     onChange: (e)=>{setStartTime(e.target.value)}
                              //   }}
                              // />
                            }
                          </div>
                          <div>
                            <lable>End Time</lable>
                            {
                              context.teams.hostClientType.web ||
                                context.teams.hostClientType.desktop ? (
                                <TeamsTimePicker
                                  required={true}
                                  min={startTime}
                                  value={endTime}
                                  onChange={(value) => {
                                    setEndTime(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0);
                                    //}
                                  }}
                                />
                              ) : (
                                <NativeTimePicker
                                  id="endTime"
                                  min={startTime}
                                  value={endTime}
                                  onChange={(value) => {
                                    setEndTime(value);
                                    setSubmitSaveAsButtonState(0);
                                    setSubmitButtonState(0);// }
                                  }}
                                  required={true}
                                />
                              )
                              // <FormField
                              //   className="timePicker"
                              //   control = {{ as: Input, value: endTime, type: 'time',
                              //     onChange: (e)=>{setEndTime(e.target.value)}
                              //   }}
                              // />
                            }
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="dropdownSection mb-10 groupFilter">
                    {filterTypes.filter((ft) => ft.isApplicable === true).length >
                      0 && (
                        <>
                          <FormDropdown
                            id="addCardOrg"
                            fluid
                            inline
                            label="Organization"
                            items={filterTypes.filter((ft) => ft.isApplicable === true)}
                            value={FilterTypes[filterTypeId]}
                            defaultValue={FilterTypes[filterTypeId]}
                            getA11ySelectionMessage={{
                              onAdd: (item) => {
                                setFilterTypeId(item.value);
                                let _selectCount = 0, _filterOption = 0;
                                if (item.value === 0) {
                                  _filterOption = card_filters[cardData.cardFilterID].users;
                                  _selectCount = users.length;
                                } else if (item.value === 1) {
                                  _filterOption = card_filters[cardData.cardFilterID].teams;
                                  _selectCount = teams.length;
                                  setTime(2);

                                } else if (item.value === 2) {
                                  _filterOption = card_filters[cardData.cardFilterID].groups;
                                  _selectCount = groups.length;
                                } else if (item.value === 3) {
                                  _filterOption = card_filters[cardData.cardFilterID].callQueues;
                                  _selectCount = callQueues.length;
                                } else if (item.value === 4) {
                                  _filterOption = card_filters[cardData.cardFilterID].agents;
                                  _selectCount = agents.length;
                                }
                                setIsValid(checkValid(item.value, _filterOption, _selectCount));
                              },
                            }}
                          />
                          {filterTypeId === 4 &&
                            card_filters[cardData.cardFilterID].agents !== 0 ? (
                            <FormDropdown
                              fluid
                              inline
                              label={`Agent${card_filters[cardData.cardFilterID].agents !== 1
                                ? "s"
                                : ""
                                } ${card_filters[cardData.cardFilterID].agents === 3
                                  ? "(only 2)"
                                  : ""
                                }`}
                              search={card_filters[cardData.cardFilterID].agents !== 1}
                              multiple={card_filters[cardData.cardFilterID].agents !== 1}
                              disabled={agentsDisabled}
                              placeholder={
                                card_filters[cardData.cardFilterID].agents !== 1
                                  ? "Start typing a name"
                                  : "Select Agent"
                              }
                              items={filterData.agentFilter.items}
                              defaultValue={agents}
                              value={agents}
                              headerMessage={card_filters[cardData.cardFilterID].agents !== 1 && card_filters[cardData.cardFilterID].agents !== 3 && (
                                <Checkbox
                                  checked={allAgents}
                                  onChange={(e, { checked }) => { handleAllAgents(checked) }}
                                  label={<Text content="Select All" weight="semibold" />}
                                />)
                              }
                              onChange={(e, { value }) => {
                                handleAgentsChange(
                                  value,
                                  card_filters[cardData.cardFilterID].agents
                                );
                              }}
                              noResultsMessage={
                                card_filters[cardData.cardFilterID].agents !== 1
                                  ? "We couldn't find any matches."
                                  : "No Agents found."
                              }
                              itemToValue={(obj) => { return obj.key; }}
                              renderSelectedItem={(Component, props) => {
                                if (props.isExpired) {
                                  props.className = props.className + " _unauthorized";
                                }
                                return <Component {...props}></Component>;
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          {filterTypeId === 3 &&
                            card_filters[cardData.cardFilterID].callQueues !== 0 ? (
                            <FormDropdown
                              fluid
                              inline
                              label={`Call Queue${card_filters[cardData.cardFilterID].callQueues !== 1
                                ? "s"
                                : ""
                                } ${card_filters[cardData.cardFilterID].callQueues === 3
                                  ? "(only 2)"
                                  : ""
                                }`}
                              search={card_filters[cardData.cardFilterID].callQueues !== 1}
                              multiple={card_filters[cardData.cardFilterID].callQueues !== 1}
                              disabled={card_filters[cardData.cardFilterID].callQueues !== 1 && callQueuesDisabled}

                              headerMessage={card_filters[cardData.cardFilterID].callQueues !== 1 && card_filters[cardData.cardFilterID].callQueues !== 3 && (
                                <Checkbox
                                  checked={allCallQueues}
                                  onChange={(e, { checked }) => { handleAllcallQueues(checked) }}
                                  label={<Text content="Select All" weight="semibold" />}
                                />)
                              }
                              placeholder={
                                card_filters[cardData.cardFilterID].callQueues !== 1
                                  ? "Start typing a name"
                                  : "Select Call Queue"
                              }
                              items={filterData.callQueueFilter.items}
                              defaultValue={callQueues}
                              value={callQueues}
                              onChange={(e, { value }) => {
                                handleCallQueuesChange(
                                  value,
                                  card_filters[cardData.cardFilterID].callQueues
                                );
                              }}
                              noResultsMessage={
                                card_filters[cardData.cardFilterID].callQueues !== 1
                                  ? "We couldn't find any matches."
                                  : "No Call Queues found."
                              }
                              itemToValue={(obj) => { return obj.key; }}
                              renderSelectedItem={(Component, props) => {
                                if (props.isExpired) {
                                  props.className = props.className + " _unauthorized";
                                }
                                return <Component {...props}></Component>;
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          {filterTypeId === 2 &&
                            card_filters[cardData.cardFilterID].groups !== 0 ? (
                            <FormDropdown
                              fluid
                              inline
                              label={`Group${card_filters[cardData.cardFilterID].groups !== 1
                                ? "s"
                                : ""
                                } ${card_filters[cardData.cardFilterID].groups === 3
                                  ? "(only 2)"
                                  : ""
                                }`}
                              search={
                                card_filters[cardData.cardFilterID].groups !== 1
                              }
                              multiple={
                                card_filters[cardData.cardFilterID].groups !== 1
                              }
                              disabled={groupsDisabled}
                              placeholder={
                                card_filters[cardData.cardFilterID].groups !== 1
                                  ? "Start typing a name"
                                  : "Select Group"
                              }
                              items={filterData.groupFilter.items}
                              defaultValue={groups}
                              value={groups}
                              headerMessage={card_filters[cardData.cardFilterID].groups !== 1 && card_filters[cardData.cardFilterID].groups !== 3 && (
                                <Checkbox
                                  checked={allGroups}
                                  onChange={(e, { checked }) => { handleAllGroups(checked) }}
                                  label={<Text content="Select All" weight="semibold" />}
                                />)
                              }
                              onChange={(e, { value }) => {
                                handleGroupChange(
                                  value,
                                  card_filters[cardData.cardFilterID].groups
                                );
                              }}
                              noResultsMessage={
                                card_filters[cardData.cardFilterID].groups !== 1
                                  ? "We couldn't find any matches."
                                  : "No groups found."
                              }
                              itemToValue={(obj) => {
                                return obj.key;
                              }}
                              renderSelectedItem={(Component, props) => {
                                if (props.isExpired) {
                                  props.className =
                                    props.className + " _unauthorized";
                                }
                                return <Component {...props}></Component>;
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          {filterTypeId === 1 && <FormCheckbox
                            className="checkBoxTeamisGroup align-items-center"
                            style={{ fontSize: "12px" }}
                            inline
                            label="Include reporting on activity outside selected Teams"
                            value={teamIsGroup}
                            checked={teamIsGroup}
                            disabled={teamIsGroupDisabled}
                            onChange={(e, { checked }) => { onTeamIsGroup(checked) }}
                          />}
                          {filterTypeId === 1 && <div></div>}
                          {filterTypeId === 1 && (card_filters[cardData.cardFilterID].teams !== 0) &&
                            card_filters[cardData.cardFilterID].groups === 0 && card_filters[cardData.cardFilterID].users === 0 &&
                            (
                              <FormDropdown
                                id="addCardTeam"
                                fluid
                                inline
                                label={`Team${card_filters[cardData.cardFilterID].teams !== 1
                                  ? ""
                                  : "(Select 1)"
                                  } ${card_filters[cardData.cardFilterID].teams === 3
                                    ? "(Select 2)"
                                    : ""
                                  }`
                                }
                                search
                                multiple
                                disabled={teamsDisabled}
                                placeholder={
                                  card_filters[cardData.cardFilterID].teams !== 1
                                    ? "Start typing a name"
                                    : "Select Team"
                                }
                                items={teamIsGroup ? teamIsGroupList : teamIsNotGroupList}
                                defaultValue={teams}
                                headerMessage={card_filters[cardData.cardFilterID].teams !== 1 && card_filters[cardData.cardFilterID].teams !== 3 && (
                                  <Checkbox
                                    checked={allTeams}
                                    onChange={(e, { checked }) => { handleAllTeams(checked) }}
                                    label={<Text content="Select All" weight="semibold" />}
                                  />)
                                }
                                value={teams}
                                onChange={(e, { value }) => {
                                  handleTeamChange(
                                    value,
                                    card_filters[cardData.cardFilterID].teams
                                  );
                                }}
                                noResultsMessage={
                                  card_filters[cardData.cardFilterID].teams !== 1
                                    ? "We couldn't find any matches."
                                    : "No teams found."
                                }
                                itemToValue={(obj) => {
                                  return obj.key;
                                }}
                                renderSelectedItem={(Component, props) => {
                                  if (props.isExpired) {
                                    props.className =
                                      props.className + " _unauthorized";
                                  }
                                  return <Component {...props}></Component>;
                                }}
                              />
                            )}
                          {filterTypeId === 0 &&
                            card_filters[cardData.cardFilterID].users !== 0 ? (

                            <FormDropdown
                              fluid
                              inline
                              label={`People${card_filters[cardData.cardFilterID].users !== 1
                                ? ""
                                : "(only 1)"
                                } ${card_filters[cardData.cardFilterID].users === 3
                                  ? "(only 2)"
                                  : ""
                                }`}
                              search
                              multiple
                              disabled={usersDisabled}
                              placeholder={
                                card_filters[cardData.cardFilterID].users !== 1
                                  ? "Start typing a name"
                                  : "Select People"
                              }
                              items={
                                card_filters[cardData.cardFilterID].users === 2
                                  ? filterData.userFilter.items.filter(
                                    (uf) => !peopleMasKList.includes(uf.key)
                                  )
                                  : filterData.userFilter.items.filter(
                                    (uf) => uf.key > 0
                                  )
                              }
                              headerMessage={card_filters[cardData.cardFilterID].users !== 1 && card_filters[cardData.cardFilterID].users !== 3 && (
                                <Checkbox
                                  checked={allUsers}
                                  onChange={(e, { checked }) => { handleAllUsers(checked) }}
                                  label={<Text content="Select All" weight="semibold" />}
                                />)
                              }
                              defaultValue={users}
                              value={users}
                              onChange={(e, { value }) => {
                                handleUserChange(
                                  value,
                                  card_filters[cardData.cardFilterID].users
                                );
                              }}
                              noResultsMessage={
                                card_filters[cardData.cardFilterID].users !== 1
                                  ? "We couldn't find any matches."
                                  : "No users found."
                              }
                              itemToValue={(obj) => {
                                return obj.key;
                              }}
                              renderSelectedItem={(Component, props) => {
                                if (props.key < 0) {
                                  props.className =
                                    props.className + " _expandable";
                                  props.onClick = (n, a) => {
                                    if (
                                      !n.currentTarget.classList.contains(
                                        "ui-dropdown__selecteditem__icon"
                                      )
                                    ) {
                                      setExpandPeople(props.key);
                                    }
                                  };
                                }
                                else if (props.isExpired) {
                                  props.className =
                                    props.className + " _unauthorized";
                                }
                                return <Component {...props}></Component>;
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          {(filterTypeId === 1 && filterData.teamFilter.items.length === 0) ?
                            <Text>You do not have permission to any team. Please get team permissions in settings</Text>
                            : <></>
                          }
                          {(filterTypeId === 2 && filterData.groupFilter.items.length === 0) ?
                            <Text>You do not have permission to any group. Please get group permissions in settings</Text>
                            : <></>
                          }
                        </>
                      )}
                    {filterTypeId === 1 &&
                      card_filters[cardData.cardFilterID].teams !== 0 && card_filters[cardData.cardFilterID].groups !== 0 ? (
                      <FormDropdown
                        id="addCardTeam"
                        fluid
                        inline
                        label={`Team${card_filters[cardData.cardFilterID].teams !== 1
                          ? ""
                          : "(only 1)"
                          } ${card_filters[cardData.cardFilterID].teams === 3
                            ? "(only 2)"
                            : ""
                          }`}



                        search
                        multiple
                        disabled={teamsDisabled}
                        placeholder={
                          card_filters[cardData.cardFilterID].teams !== 1
                            ? "Start typing a name"
                            : "Select Team"
                        }
                        items={teamIsGroup ? teamIsGroupList : teamIsNotGroupList}
                        defaultValue={teams}
                        value={teams}
                        onChange={(e, { value }) => {
                          handleTeamChange(
                            value,
                            card_filters[cardData.cardFilterID].teams
                          );
                        }}
                        noResultsMessage={
                          card_filters[cardData.cardFilterID].teams !== 1
                            ? "We couldn't find any matches."
                            : "No teams found."
                        }
                        itemToValue={(obj) => {
                          return obj.key;
                        }}
                        renderSelectedItem={(Component, props) => {
                          if (props.isExpired) {
                            props.className =
                              props.className + " _unauthorized";
                          }
                          return <Component {...props}></Component>;
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Segment>
                <Flex fill gap="gap.small" styles={{ height: 'fit-content' }}>
                  {card_filters[cardData.cardFilterID].modalities === 1 ||
                    card_filters[cardData.cardFilterID].callQuality === 1 || card_filters[cardData.cardFilterID].callParticipants === 1 || card_filters[cardData.cardFilterID].joinedAs === 1 ? (
                    <Flex.Item>
                      <Segment className="pt-0 shadow-none border-right" styles={{ width: "100%" }}>
                        <Header as="h6" content={`Meetings/Calls`} />
                        <Flex
                          fill
                          column
                          vAlign="stretch"
                          styles={{
                            height: "calc(100% - 2em)",
                          }}
                        >
                          {card_filters[cardData.cardFilterID].modalities ===
                            1 ? (
                            <Flex wrap column className="mb-1">
                              <label className="mb-0">Type</label>
                              <Flex.Item>
                                <Flex wrap>
                                  {filterTypeId !== 1 && modalityTypes.map((modalityType) => {
                                    return (
                                      <FormCheckbox
                                        key={modalityType.modalityID}
                                        checked={
                                          modalities[modalityType.modalityID] ===
                                          true
                                        }
                                        label={modalityType.modalityName}
                                        value={modalityType.modalityID}
                                        style={{ display: cardData.cardWidgetID === 5 && modalityType.modalityID === 2 ? 'none' : 'inline-grid' }}
                                        onChange={(e, { checked }) => {
                                          if (checked) {
                                            //add
                                            setModalities({
                                              ...modalities,
                                              [modalityType.modalityID]: true,
                                            });
                                          } else {
                                            //remove
                                            setModalities({
                                              ...modalities,
                                              [modalityType.modalityID]: false,
                                            });
                                          }
                                          setSubmitSaveAsButtonState(0);
                                          setSubmitButtonState(0);
                                        }}
                                      />
                                    );
                                  })
                                  }
                                  {filterTypeId === 1  && teamModalityTypes.map((modalityType) => {
                                    return (
                                      <FormCheckbox
                                        key={modalityType.modalityID}
                                        checked={
                                          modalities[modalityType.modalityID] ===
                                          true
                                        }
                                        label={modalityType.modalityName}
                                        value={modalityType.modalityID}
                                        style={{ display: cardData.cardWidgetID === 5 && modalityType.modalityID === 2 ? 'none' : 'inline-grid' }}
                                        onChange={(e, { checked }) => {
                                          if (checked) {
                                            //add
                                            setModalities({
                                              ...modalities,
                                              [modalityType.modalityID]: true,
                                            });
                                          } else {
                                            //remove
                                            setModalities({
                                              ...modalities,
                                              [modalityType.modalityID]: false,
                                            });
                                          }
                                          setSubmitSaveAsButtonState(0);
                                          setSubmitButtonState(0);
                                        }}
                                      />
                                    );
                                  })
                                  }
                                </Flex>
                              </Flex.Item>
                            </Flex>
                          ) : (
                            <></>
                          )}
                          {card_filters[cardData.cardFilterID].callQuality ===
                            1 ? (
                            <Flex wrap column className="mb-1">
                              <label className="mb-0">Network Quality</label>
                              <Flex.Item>
                                <Flex wrap>
                                  {callQualityTypes.map((callQualityType) => {
                                    return (
                                      <FormCheckbox
                                        key={callQualityType.callQualityID}
                                        label={callQualityType.Description}
                                        value={callQualityType.callQualityID}
                                        checked={
                                          callQualities[
                                          callQualityType.callQualityID
                                          ] === true
                                        }
                                        onChange={(e, { checked }) => {
                                          if (checked) {
                                            //add
                                            setCallQualities({
                                              ...callQualities,
                                              [callQualityType.callQualityID]: true,
                                            });
                                          } else {
                                            //remove
                                            setCallQualities({
                                              ...callQualities,
                                              [callQualityType.callQualityID]: false,
                                            });
                                          } setSubmitSaveAsButtonState(0);
                                          setSubmitButtonState(0);
                                        }}
                                      />
                                    );
                                  })}
                                </Flex>
                              </Flex.Item>
                            </Flex>
                          ) : (
                            <></>
                          )}
                          {card_filters[cardData.cardFilterID].callParticipation === 1 ? (
                            <Flex wrap column className="mb-1">
                              <label className="mb-0">Participation</label>
                              <Flex.Item>
                                <Flex wrap>
                                  {callParticipantsTypes.map((participationType) => {
                                    return (
                                      <FormCheckbox
                                        key={participationType.Id}
                                        label={participationType.Type}
                                        value={participationType.Id}
                                        checked={
                                          callParticipants[participationType.Id] ===
                                          true
                                        }
                                        onChange={(e, { checked }) => {
                                          if (checked) {
                                            //add
                                            setcallParticipants({
                                              ...callParticipants,
                                              [participationType.Id]: true,
                                            });
                                          } else {
                                            //remove
                                            setcallParticipants({
                                              ...callParticipants,
                                              [participationType.Id]: false,
                                            });
                                          } setSubmitSaveAsButtonState(0);
                                          setSubmitButtonState(0);
                                        }}
                                      />
                                    );
                                  })}
                                </Flex>
                              </Flex.Item>
                            </Flex>
                          ) : (
                            <></>
                          )}
                          {card_filters[cardData.cardFilterID].joinedAs === 1 && filterTypeId !== 1 ? (
                            <Flex wrap column className="mb-1">
                              <label className="mb-0">Joined As</label>
                              <Flex.Item>
                                <Flex wrap>
                                  {joinedAsTypes.map((joinedType) => {
                                    return (
                                      <FormCheckbox
                                        key={joinedType.Id}
                                        label={joinedType.Type}
                                        value={joinedType.Id}
                                        checked={
                                          joinedAs[joinedType.Id] ===
                                          true
                                        }
                                        onChange={(e, { checked }) => {
                                          if (checked) {
                                            //add
                                            setJoinedAs({
                                              ...joinedAs,
                                              [joinedType.Id]: true,
                                            });
                                          } else {
                                            //remove
                                            setJoinedAs({
                                              ...joinedAs,
                                              [joinedType.Id]: false,
                                            });
                                          } setSubmitSaveAsButtonState(0);
                                          setSubmitButtonState(0);
                                        }}
                                      />
                                    );
                                  })}
                                </Flex>
                              </Flex.Item>
                            </Flex>
                          ) : (
                            <></>
                          )}
                          {card_filters[cardData.cardFilterID].collaborationType === 1 && filterTypeId !== 1 ? (
                            <Flex wrap column className="mb-1">
                              <label className="mb-0">Collaboration Type</label>
                              <Flex.Item>
                                <Flex wrap>
                                  {collaborationTypes.map((collabType) => {
                                    return (
                                      <FormCheckbox
                                        key={collabType.Id}
                                        label={collabType.Type}
                                        value={collabType.Id}
                                        checked={
                                          collaborationType[collabType.Id] ===
                                          true
                                        }
                                        onChange={(e, { checked }) => {
                                          if (checked) {
                                            //add
                                            setCollaborationType({
                                              ...collaborationType,
                                              [collabType.Id]: true,
                                            });
                                          } else {
                                            //remove
                                            setCollaborationType({
                                              ...collaborationType,
                                              [collabType.Id]: false,
                                            });
                                          } setSubmitSaveAsButtonState(0);
                                          setSubmitButtonState(0);
                                        }}
                                      />
                                    );
                                  })}
                                </Flex>
                              </Flex.Item>
                            </Flex>
                          ) : (
                            <></>
                          )}
                        </Flex>
                      </Segment>
                    </Flex.Item>
                  ) : (
                    <></>
                  )}
                  {card_filters[cardData.cardFilterID].messages === 1 ||
                    card_filters[cardData.cardFilterID].reactions === 1 ||
                    card_filters[cardData.cardFilterID].sentiment === 1 || card_filters[cardData.cardFilterID].msgParticipation === 1 ? (
                    <Flex.Item>
                      <Segment className="pt-0 shadow-none" styles={{ width: "100%" }}>
                        <Header as="h6" content={`Messages`} />
                        {/* {
                              card_filters[cardData.cardFilterID].messages === 1 ?
                              <Flex  className="mb-1">
                                  <FormCheckbox checked={messages} label="Include Chat" toggle onChange={(e, {checked}) => {setMessages(checked)}} />
                              </Flex>
                              :<></>
                          }     */}

                        {card_filters[cardData.cardFilterID].reactions === 1 ? (
                          <Flex wrap column className="mb-1">
                            <label className="mb-0">Reactions</label>
                            <Flex.Item>
                              <Flex wrap>
                                {reactionTypes.map((reactionType) => {
                                  return (
                                    <FormCheckbox
                                      key={reactionType.reactionTypeID}
                                      label={reactionType.Description}
                                      value={reactionType.reactionTypeID}
                                      checked={
                                        reactions[reactionType.reactionTypeID] ===
                                        true
                                      }
                                      onChange={(e, { checked }) => {
                                        if (checked) {
                                          //add
                                          setReactions({
                                            ...reactions,
                                            [reactionType.reactionTypeID]: true,
                                          });
                                        } else {
                                          //remove
                                          setReactions({
                                            ...reactions,
                                            [reactionType.reactionTypeID]: false,
                                          });
                                        } setSubmitSaveAsButtonState(0);
                                        setSubmitButtonState(0);
                                      }}
                                    />
                                  );
                                })}
                              </Flex>
                            </Flex.Item>
                          </Flex>
                        ) : (
                          <></>
                        )}

                        {card_filters[cardData.cardFilterID].sentiment === 1 ? (
                          <Flex wrap column className="mb-1">
                            <label className="mb-0">Sentiment</label>
                            <Flex.Item>
                              <Flex wrap>
                                {sentimentTypes.map((sentimentType) => {
                                  return (
                                    <FormCheckbox
                                      key={sentimentType.sentimentAnalysisTypeID}
                                      label={sentimentType.description}
                                      value={
                                        sentimentType.sentimentAnalysisTypeID
                                      }
                                      checked={
                                        sentiments[
                                        sentimentType.sentimentAnalysisTypeID
                                        ] === true
                                      }
                                      onChange={(e, { checked }) => {
                                        if (checked) {
                                          //add
                                          setSentiments({
                                            ...sentiments,
                                            [sentimentType.sentimentAnalysisTypeID]: true,
                                          });
                                        } else {
                                          //remove
                                          setSentiments({
                                            ...sentiments,
                                            [sentimentType.sentimentAnalysisTypeID]: false,
                                          });
                                        } setSubmitSaveAsButtonState(0);
                                        setSubmitButtonState(0);
                                      }}
                                    />
                                  );
                                })}
                              </Flex>
                            </Flex.Item>
                          </Flex>
                        ) : (
                          <></>
                        )}
                        {card_filters[cardData.cardFilterID].msgParticipation === 1 ? (
                          <Flex wrap column className="mb-1">
                            <label className="mb-0">Participation</label>
                            <Flex.Item>
                              <Flex wrap>
                                {msgParticipantsTypes.map((participationType) => {
                                  return (
                                    <FormCheckbox
                                      key={participationType.Id}
                                      label={participationType.Type}
                                      value={participationType.Id}
                                      checked={
                                        msgParticipants[participationType.Id] ===
                                        true
                                      }
                                      onChange={(e, { checked }) => {
                                        if (checked) {
                                          //add
                                          setmsgParticipants({
                                            ...msgParticipants,
                                            [participationType.Id]: true,
                                          });
                                        } else {
                                          //remove
                                          setmsgParticipants({
                                            ...msgParticipants,
                                            [participationType.Id]: false,
                                          });
                                        } setSubmitSaveAsButtonState(0);
                                        setSubmitButtonState(0);
                                      }}
                                    />
                                  );
                                })}
                              </Flex>
                            </Flex.Item>
                          </Flex>
                        ) : (
                          <></>
                        )}
                      </Segment>
                    </Flex.Item>
                  ) : (
                    <></>
                  )}
                </Flex>
              </div>
              <Flex
                className="align-items-center pt-2"
                gap="gap.small"
                fill
                hAlign="end"
                styles={{ height: 'auto', paddingBottom: "0 !important", marginTop: "0 !important" }}
              >
                {!isUpdate && submitButtonState === 2 && <div className="col text-right px-0">New card has been added.</div>}
                {isDemo && <div className="col text-right px-0">Exploring app with sample data.</div>}
                {(isValid) && (
                  <Button
                    content={context.windowSize.width < 799 ? "" : "Preview"}
                    onClick={(event) => onLivePreviewClick(event)}
                    disabled={previewing}
                    loading={previewing}
                    iconOnly={context.windowSize.width < 799}
                    icon={<EyeFriendlierIcon />}
                  />
                )}

                {!isUpdate && (
                  <FormButton

                    content={submitButtonState === 2 ? "Close" : "Cancel"}
                    onClick={() => {
                      props.cancelHandler() &&
                        setCardNameError(false);
                      setSubmitButtonState(0);
                    }}
                  />
                )}
                {/* {isDemo &&
                  (<FormButton
                    disabled={isValid === false || submitDemoButtonState !== 0}
                    loading={submitDemoButtonState === 1}
                    content="Save"
                    style={isValid ? {
                      color: "#212529",
                      backgroundColor: "#ffc107",
                      borderColor: "#ffc107"
                    } : null}
                  />)} */}

                {<FormButton
                  id="addCardSave"
                  disabled={(isValid === false || previewing === true || submitButtonState !== 0 || isCardNameValid === false)}//|| stepIndex !== 5)}
                  icon={submitButtonState === 2 && <AcceptIcon />}
                  loading={submitButtonState === 1}
                  onClick={() => { setSaveAs(0); introClickHit() }} //introClickHit() }}
                  content={
                    submitButtonState === 0
                      ? "Save"
                      : submitButtonState === 1
                        ? "Saving"
                        : submitButtonState === 2
                          ? "Saved"
                          : "Failed"
                  }
                  primary
                />}
                {/* {isUpdate && <Tooltip content="Save as new card" trigger={
                  <FormButton
                    disabled={isValid === false || previewing === true || submitButtonState !== 0}
                    icon={submitButtonState === 2 && <AcceptIcon />}
                    loading={submitButtonState === 1}
                    onClick={() => { setSaveAs(1); }}
                    iconOnly
                    pointing
                    title="Save as new card"
                    content={
                      submitButtonState === 0
                        ? <AddIcon />
                        : submitButtonState === 1
                          ? ""
                          : submitButtonState === 2
                            ? <AcceptIcon />
                            : <ErrorIcon />
                    }
                    primary
                  />
                } />
                } */}
                {isUpdate && <Tooltip content="Save as new card" trigger={<FormButton
                  disabled={isValid === false || previewing === true || submitSaveAsButtonState !== 0}
                  icon={submitSaveAsButtonState === 2 && <AcceptIcon />}
                  loading={submitSaveAsButtonState === 1}
                  onClick={() => setSaveAs(1)}
                  content={
                    submitSaveAsButtonState === 0
                      ? "Save As"
                      : submitSaveAsButtonState === 1
                        ? "Saving"
                        : submitSaveAsButtonState === 2
                          ? "Saved As"
                          : "Failed"
                  }
                  primary
                />} />
                }
              </Flex>
            </Form>
            {
              livePreview &&
              <KendoDialog
                className="livePreviewDialog"
                autoFocus={true}
                title={livePreviewData ? livePreviewData.Name : ""}
                onClose={() => onLivePreviewClose()}
              >
                <div style={{ height: 'calc(100% - 33px)', padding: '10px 4px' }}>
                  <CardLivePreview
                    livePreviewData={livePreviewData}
                    demo={props.demo}
                  />
                </div>
                <Flex hAlign="end" gap="gap.small">
                  <Button
                    size="medium"
                    content="Close"
                    onClick={onLivePreviewClose}
                  />
                </Flex>
              </KendoDialog>
            }

            <Dialog
              style={{ maxWidth: "300px" }}
              backdrop={true}
              open={expandPeople < 0}
              footer={{
                children: (Component, props) => {
                  const { styles, ...rest } = props;
                  return (
                    <Flex styles={styles} hAlign="center" gap="gap.small">
                      <Button
                        size="small"
                        content="Cancel"
                        onClick={onExpandCancel}
                      />
                      <Button
                        size="small"
                        primary
                        content="Expand"
                        onClick={onExpandConfirm}
                      />
                    </Flex>
                  );
                },
              }}
              content={
                <>
                  <Flex
                    fill
                    hAlign="center"
                    style={{ paddingBottom: "15px" }}
                    gap="gap.small"
                  >
                    <ExclamationTriangleIcon
                      size="largest"
                      styles={{ color: "#fce100" }}
                    />
                    <Flex.Item>
                      <Text content="If you expand the list, the list will be replaced by individual members. It is not possible to collapse the list again." />
                    </Flex.Item>
                  </Flex>
                </>
              }
            />
          </>
        ) : (
          <Loader />
        )
      }
    </WindowContext.Consumer>
  );
};
export default SingleCardSettings;
