import React, { useEffect, useState } from 'react'
import InsightsCard from './insightsCard'
import './insights.scss';
import { Avatar, Text, Loader, Flex, FlexItem, Box, Skeleton } from '@fluentui/react-northstar';
import { Dialog } from '@progress/kendo-react-dialogs';
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import DashboardApiService from '../../../services/DashboardApiService';
import AuthHelper from '../../../services/auth-helper';
import FilterPopup from './FilterPopup';
import { Breadcrumb } from '@fluentui/react-northstar'
import { AddIcon, ChevronEndMediumIcon, PersonIcon } from '@fluentui/react-icons-northstar'
import InsightsCardSkeleton from './insightsCardSkeleton';

const transformData = (apiData, setManagerData, numberOfDays, categoryData) => {
    let customerProfileMap = apiData.customerProfileDetails?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
    }, {});
    let customerProfile;
    if (apiData.managerProfileDetails) {
        customerProfile = customerProfileMap[apiData.managerProfileDetails.customerProfileId];
        const manager = apiData.managerProfileDetails;
        const managerData =
        {
            vibe: 'positive',
            userName: manager.displayName,
            userGUID: manager.userGUID,
            userDesignation: manager.jobTitle,
            userEmail: manager.mail,
            userImage: manager.userImage,
            reporteeCount: manager.reporteeCount,
            userStatus: 'success',
            reportee: 'Shiv',
            reporteeDesignation: 'CTO',
            reporteeStatus: 'error',
            reporteeImage: '',
            progressType: 'productive-hours',
            meetingsPercentage: Number(((manager.meetingTime / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
            callsPercentage: Number((((manager.messageTime + manager.callTime) / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
            focusedPercentage: Number(((((manager.avgWorkingHours * 3600 * numberOfDays) - (manager.meetingTime + manager.callTime + manager.messageTime)) / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
            meetingsZone: customerProfile.meetingsZone ? customerProfile.meetingsZone : null,
            callsZone: customerProfile.callsZone ? customerProfile.callsZone : null,
            focusedZone: customerProfile ? customerProfile.focusedZone : null,
            expectedMeetingsPercentage: customerProfile ? customerProfile.percentageTimeInMeetings : 0,
            expectedCallsPercentage: customerProfile ? customerProfile.percentageTimeInCallsAndMessages : 0,
            expectedFocusedPercentage: customerProfile ? customerProfile.percentageTimeInFocus : 0,
            dviNeutralVal: Number(manager.neutralSentmentScore * 100),
            dviPositiveVal: Number(manager.positiveSentmentScore * 100),
            dviNegativeVal: Number(manager.negetiveSentmentScore * 100),
            questionTag: categoryData.filter(tag => tag.isSelected === 1).map(tag => ({
                label: tag.questionCategoryName,
                val: 2
            })),
            lvTypeMeeting: 'Negative',
            lvTypeMessages: 'Neutral',
            lvTypeFocused: 'Positive',
        }
        setManagerData(managerData);
    } else {
        setManagerData(null);
    }
    return apiData.userDetails.map(user => {
        customerProfile = customerProfileMap[user.customerProfileId];
        const avgWorkingHoursInSeconds = user.avgWorkingHours * 3600;

        return {
            vibe: 'neutral',
            userName: user.displayName,
            userGUID: user.userGUID,
            userDesignation: user.jobTitle,
            userEmail: user.mail,
            userImage: user.userImage,
            reporteeCount: user.reporteeCount,
            userStatus: 'success',
            reportee: 'Reportee Name',
            reporteeDesignation: 'Reportee Designation',
            reporteeStatus: 'success',
            reporteeImage: '',
            progressType: 'productive-hours',
            meetingsPercentage: Number(((user.meetingTime / avgWorkingHoursInSeconds) * 100).toFixed(1)),
            callsPercentage: Number((((user.messageTime + user.callTime) / avgWorkingHoursInSeconds) * 100).toFixed(1)),
            focusedPercentage: Number((((avgWorkingHoursInSeconds - (user.meetingTime + user.callTime + user.messageTime)) / avgWorkingHoursInSeconds) * 100).toFixed(1)),
            meetingsZone: customerProfile.meetingsZone ? customerProfile.meetingsZone : null,
            callsZone: customerProfile.callsZone ? customerProfile.callsZone : null,
            focusedZone: customerProfile ? customerProfile.focusedZone : null,
            expectedMeetingsPercentage: customerProfile ? customerProfile.percentageTimeInMeetings : 0,
            expectedCallsPercentage: customerProfile ? customerProfile.percentageTimeInCallsAndMessages : 0,
            expectedFocusedPercentage: customerProfile ? customerProfile.percentageTimeInFocus : 0,
            dviNeutralVal: Number(user.neutralSentmentScore * 100),
            dviPositiveVal: Number(user.positiveSentmentScore * 100),
            dviNegativeVal: Number(user.negetiveSentmentScore * 100),
            questionTag: categoryData.filter(tag => tag.isSelected === 1).map(tag => ({
                label: tag.questionCategoryName,
                val: 2
            })),
            lvTypeMeeting: 'Neutral',
            lvTypeMessages: 'Neutral',
            lvTypeFocused: 'Neutral',
        };
    });
};


const ProfileSettingHeader = (
    { profileName = 'Profile Name', sysDefine = false, assignedTo = '! No data', handleUsers, handleEdit, handleDelete }
) => {
    return (
        <div className='row insights-card-header align-items-center'>
            <div className='col px-2 labels'>
                <div className='name-label'>{profileName}</div>
                {sysDefine && <div className='org-email'>System defined</div>}
                <div onClick={handleUsers} className='assigned-users-label'>Assigned to {assignedTo} users</div>
            </div>
            <div className='col d-flex px-2 labels justify-content-end'>
                <div className="mx-1"><button onClick={handleEdit} className='bg-custome-light border px-3 py-1 rounded-pill'>Edit</button></div>
                <div className="mx-1"><button onClick={handleDelete} className='bg-danger-light px-2 py-1 rounded-pill'>Delete</button></div>
            </div>
        </div >
    )
}

const ListViewIndicator = ({ type, label = 'Activity' }) => {
    return (
        <div className='col-auto px-0 mr-2 d-flex align-items-center'>
            {type && <div className={`list-view-indicator ${type}`} title={`${type} zone`}></div>}
            <div className='list-view-label px-2' title={label === 'Messages' ? 'Chats & Messages' : label}>{label}</div>
        </div>
    )
}

const TeamVibe = (
    { managerVibe, loading = false, managerName = '!No data', managerStatus = 'unknown', managerDesignation = '!No data' }
) => {
    return (
        <div className='row align-items-center'>

            <div className="insights-card cursor-default mx-3 shadow-bg-filter-header">
                <div className="row">
                    {!loading ? <div className="col align-items-center justify-content-center insights-card-header">
                        <div className='name-label'>Team Vibe</div>
                        <div className='vibe-indicator align-self-end mt-4'>
                            <div className='vibe-indicator-emoji' >
                                {managerVibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                    : (managerVibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                        : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                    )}
                            </div>
                            <div className='vibe-indicator-text bg-primary text-right'>
                                {managerVibe === 'positive' ? 'Positive' : (managerVibe === 'negative' ? 'Negative' : 'Neutral')}
                            </div>
                        </div>
                    </div> : <div className="col align-items-center justify-content-center insights-card-header"><Skeleton.Shape width='60px' height='60px' /></div>}
                </div>
            </div>
        </div>
    )
}

const InsightsHeader = (
    { managerVibe, managerData, loading = false, managerStatus = 'success', managerDesignation = '!No data', ringCounts = 7, fetchData, stack, setStack, setSelectedMyNetwork }
) => {
    const [headDViewOpen, setHeadDViewOpen] = useState(false)
    const handleDialogOpen = () => {
        setHeadDViewOpen(!headDViewOpen)
    }
    return (
        <div>
            <div className='row align-items-center'>
                <div className='position-relative cursor-default'>
                    {managerData && !loading ?
                        <Avatar
                            className='shadow rounded-circle'
                            style={{ border: "solid 2px white" }}
                            size='largest'
                            name={managerData.userName}
                            status={managerData.status}
                            image={managerData.userImage}
                        /> :
                        <Skeleton animation='pulse'><Skeleton.Avatar size='largest' className='shadow rounded-circle' /></Skeleton>
                    }
                    {
                        Array.from({ length: ringCounts }).map((_, index) => (
                            <div key={index} className={`user-rings ${ringCounts <= 4 ? 'rings-danger' : ringCounts >= 7 ? 'rings-success' : 'rings-warning'}`} style={{ height: `${(index * 16) * (index * 2) + 'px'}`, width: `${(index * 16) * (index * 2) + 'px'}` }}></div>
                        ))
                    }
                </div>
                {(managerData && !loading) ?
                    <div className="insights-card cursor-default mx-3 shadow-bg-filter-header cursor-pointer" onClick={handleDialogOpen}>
                        <div className="container">
                            <div className="row align-items-center insights-card-header">
                                <div className='col px-0 labels border-right mr-3'>
                                    <div className='name-label'>{managerData.userName}</div>
                                    <div className='designation-label'>{managerData.userDesignation}</div>
                                    <div className="insights-card-body">
                                        <div className="container">
                                            <div className='row gap-2 justify-content-center'>
                                                <ListViewIndicator type='Neutral' label='Meetings' />
                                                <ListViewIndicator type='Positive' label='Messages' />
                                                <ListViewIndicator type='Neutral' label='Focused' />
                                            </div>
                                        </div>
                                    </div>
                                    {/* {detailView && <div className='org-email'>{userEmail}</div>} */}
                                </div>
                                <div className='vibe-indicator align-self-end mb-2'>
                                    <div className='vibe-indicator-emoji' >
                                        {managerVibe === 'positive' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png" alt="Neutral Face" width="38" height="38" />
                                            : (managerVibe === 'negative' ? <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png" alt="Worried Face" width="38" height="38" />
                                                : <img src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png" alt="Neutral Face" width="38" height="38" />
                                            )}
                                    </div>
                                    <div className='vibe-indicator-text bg-primary text-right'>
                                        {managerVibe === 'positive' ? 'Positive' : (managerVibe === 'negative' ? 'Negative' : 'Neutral')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div className="insights-card cursor-default mx-3 shadow-bg-filter-header cursor-pointer" style={{ width: "392.6px" }}>
                        <div className="container">
                            <Skeleton animation='pulse'>
                                <div className="row align-items-center insights-card-header">
                                    <div className='col px-0 labels border-right mr-3'>
                                        <Skeleton.Line width="75%" />
                                        <Skeleton.Line width="40%" />
                                        <div className="insights-card-body">
                                            <div className="container">
                                                <div className='row gap-2 justify-content-center'>
                                                    <Skeleton.Line width="80%" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* {detailView && <div className='org-email'>{userEmail}</div>} */}
                                    </div>
                                    <Skeleton.Shape width='60px' height='60px' />
                                </div>
                            </Skeleton>
                        </div>
                    </div>}
            </div>
            {/* <BreadCrumb stack={stack} setStack={setStack} fetchData={fetchData} setSelectedMyNetwork={setSelectedMyNetwork} /> */}
            {
                (headDViewOpen) && (<Dialog
                    width={464}
                    height={464}
                    title={<div className='name-label'>Detail View</div>}
                    onClose={handleDialogOpen}
                >
                    <InsightsCard key={1} detailView={true} forDialog {...managerData} fetchData={fetchData} />
                </Dialog>)
            }
        </div>
    )
}

const BreadCrumb = ({ stack, setStack, fetchData, setSelectedMyNetwork }) => {
    const handleBreadcrumbClick = (index, userguid, ismanager) => {
        setStack(stack.slice(0, index));
        setSelectedMyNetwork(null);
        if (ismanager) {
            fetchData(userguid);
        } else {
            fetchData(null, userguid);
        }

    };

    return (
        <div>
            <Breadcrumb aria-label="breadcrumb">
                {stack.map((user, index) => (
                    <React.Fragment key={user.userguid}>
                        <Breadcrumb.Item>
                            {index === stack.length - 1 ? (
                                <span>{user.username}</span>
                            ) : (
                                <Breadcrumb.Link
                                    href="javascript:void(0);"
                                    onClick={() => handleBreadcrumbClick(index, user.userguid, user.ismanager)}
                                >
                                    {user.username}
                                </Breadcrumb.Link>
                            )}
                        </Breadcrumb.Item>
                        {index < stack.length - 1 && (
                            <Breadcrumb.Divider>
                                <ChevronEndMediumIcon />
                            </Breadcrumb.Divider>
                        )}
                    </React.Fragment>
                ))}
            </Breadcrumb>
        </div>
    );
};

function Insights() {
    const [managerIDSelected, setManagerIDSelected] = useState(null);
    const [userIDSelected, setUserIDSelected] = useState(null);
    const [myNetworkData, setMyNetworkData] = useState(null);
    const [stack, setStack] = useState([]);
    const [categoryData, setCategoryData] = useState([]);

    const addItemToStack = (username, userguid, ismanager) => {
        setStack(prevStack => {
            const lastEntry = prevStack[prevStack.length - 1];
            if (lastEntry && lastEntry.userguid === userguid) {
                return prevStack;
            }
            return [...prevStack, { username, userguid, ismanager }];
        });
    };

    const calculateWorkingDays = (startTime, endTime) => {
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);
        if (endDate < startDate) {
            return 0;
        }

        let numberOfWorkingDays = 0;

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const dayOfWeek = date.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                numberOfWorkingDays++;
            }
        }
        return numberOfWorkingDays;
    }

    const fetchData = (managerId = null, userGUID = null) => {
        const APIdata = {
            StartTime: startDate,
            EndTime: endDate,
            TimeCategory: timeCategory
        };
        if (managerId !== null) {
            APIdata.ManagerId = managerId;
            APIdata.UserGUID = null;
            setManagerIDSelected(managerId);
            setUserIDSelected(null);
        }
        else if (userGUID !== null) {
            APIdata.UserGUID = userGUID;
            APIdata.ManagerId = null;
            setManagerIDSelected(null);
            setUserIDSelected(userGUID);
        } else if (managerIDSelected !== null) {
            APIdata.ManagerId = managerIDSelected;
        } else if (userIDSelected !== null) {
            APIdata.UserGUID = userIDSelected;
        }
        const numberOfDays = calculateWorkingDays(APIdata.StartTime, APIdata.EndTime);
        setLoading(true);
        AuthHelper.getAccessToken((token) => {
            ConfigurationApiService.GetUsersDataforInsights(token, APIdata).then((response) => {
                if (response) {
                    const transformedData = transformData(response, setManagerData, numberOfDays, categoryData);
                    setData(transformedData);
                    if (response.managerProfileDetails) {
                        const manager = response.managerProfileDetails;
                        addItemToStack(manager.displayName, manager.userGUID, true)
                    } else {
                        addItemToStack(transformedData[0].userName, transformedData[0].userGUID, false)
                    }

                } else {
                    alert('Error in fetching data');
                }
                setLoading(false);
            });
        });
    }

    const loadUserProfileData = () => {
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetUserProfiles(token).then(
                (response) => {
                    if (response != undefined && response != "") {
                        if (response.userProfiles !== "") {
                            const myPeopleDropdownValues = response.userProfiles.map(user => ({
                                id: user.isManager,
                                key: user.userGUID,
                                header: user.displayName,
                                image: user.isManager ? '../../images/isManagerUser.png' : '../../images/isUser.png',
                                content: 'Role'
                            }));
                            setMyNetworkData(myPeopleDropdownValues);
                        } else {
                            const defaultEntry = [{
                                id: 1,
                                key: 'no-network',
                                header: 'No Network Below You',
                                disabled: true
                            }];

                            setMyNetworkData(defaultEntry);
                        }
                        if (response.categoryData !== "") {
                            setCategoryData(response.categoryData);

                        }
                    }
                    window.dispatchEvent(new Event("resize"));
                }
            );
        });
    };

    useEffect(() => {
        if (categoryData.length > 0) {
            fetchData("665633a2-6100-4244-aea6-6c50d09de480");
        } else {
            loadUserProfileData();
        }
    }, [categoryData]);
    const [data, setData] = useState([]);
    const [managerData, setManagerData] = useState();
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timeCategory, setTimeCategory] = useState(1);
    const [period, setPeriod] = useState(1);
    const [selectedMyNetwork, setSelectedMyNetwork] = useState(null);

    return (
        <div>
            <Flex space='between' vAlign='center' gap="gap.small" className='bg-light'>
                <BreadCrumb stack={stack} setStack={setStack} fetchData={fetchData} setSelectedMyNetwork={setSelectedMyNetwork} />
                <FlexItem push>
                    <FilterPopup
                        startDate={startDate}
                        period={period}
                        setPeriod={setPeriod}
                        endDate={endDate}
                        timeCategory={timeCategory}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                        setTimeCategory={setTimeCategory}
                        fetchData={fetchData}
                        myNetworkData={myNetworkData}
                        setSelectedMyNetwork={setSelectedMyNetwork}
                        selectedMyNetwork={selectedMyNetwork} />
                </FlexItem>
            </Flex>
            <>
                <div className="container container-cst align-items-center pr-0 my-4">
                    <div className='row my-3 justify-content-between'>
                        <InsightsHeader loading={loading} managerData={managerData} fetchData={fetchData} stack={stack} setStack={setStack} setSelectedMyNetwork={setSelectedMyNetwork}
                        />

                        <Flex>
                            <TeamVibe loading={loading} />
                        </Flex>
                    </div>
                    {/* <div className='container'>
                                    <div className='insights-card cursor-default m-3 detail-view-width'>
                                        <div className="container h-100">
                                            <ProfileSettingHeader
                                                sysDefine
                                                assignedTo={34}
                                            />
                                            <div className="insights-card-body">
                
                                                <div className='row border-top pt-2'>
                                                    <div className="col px-2">
                                                        <HSCProgress progressLabel={false} type={'productive-hours'} label={'Meetings'} setMark={14} />
                                                        <HSCProgress progressLabel={false} type={'productive-hours'} label={'Messages'} setMark={32} />
                                                        <HSCProgress progressLabel={false} type={'productive-hours'} label={'Focused'} setMark={54} />
                                                    </div>
                                                </div>
                
                                            </div>
                
                                        </div>
                                    </div>
                                </div> */}
                </div>
                {/* <div className="container my-3">
                                    <div className='row'>
                                        <div className="col text-right">
                                            <button className='bg-custome-light border rounded-pill px-3 py-2' onClick={() => setDView(!dView)}>detail View</button>
                                        </div>
                                    </div>
                                </div> */}

                <div className="container container-cst pr-0 mt-4" style={{ height: '496px', overflowY: 'auto' }}>
                    <div className="row gap-24 mx-auto my-3">
                        {loading ? Array.from({ length: 6 }).map((_, index) => (
                            <InsightsCardSkeleton
                                key={index}
                                detailView={true} />
                        )) :
                            data.map((d, index) =>
                                <InsightsCard
                                    key={index}
                                    detailView={true}
                                    {...d}
                                    fetchData={fetchData} />
                            )}
                    </div>
                </div>

            </>
        </div>
    )
}

export default Insights