import axios, { AxiosInstance } from 'axios';
import AuthHelper from './auth-helper';
import { AuthenticationResult } from '@azure/msal-browser';
import * as constants from '../constants';

class ConfigurationApiService {

    static AuthenticatedApi = axios.create({
        baseURL: constants.Api.baseUri,
        headers: { 
            'Content-Type': 'application/json',
        }
    });

    // Users

    static DeleteCustomerCommunicationProfiles(token,selectedid,alternateId) {
        return this.AuthenticatedApi.delete(`/comprofile/Delete/${selectedid}/${alternateId}`, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetCustomerCommunicationProfiles(token) {
        return this.AuthenticatedApi.get('/comprofile/Get', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetAssignedUsersProfile(token,customerProfileId) {
        return this.AuthenticatedApi.get('/comprofile/GetAssignedUsers/' + customerProfileId, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static AddorUpdateCustomerCommunicationProfiles(token,data) {
        return this.AuthenticatedApi.post('/comprofile/AddorUpdate', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateAssignedProfiles(token,data) {
        return this.AuthenticatedApi.post('/comprofile/UpdateAssignedUser', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUsersDataforInsights(token,data) {
        return this.AuthenticatedApi.post('/comprofile/GetUserProfileDetails',data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetLoggedInUserDetail(token) {
        return this.AuthenticatedApi.get('/UserConfig/GetLoggedInUserDetail', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUsers(token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUsers', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserGroupInheritance(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateUserGroupInheritance', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateAdministrativeAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateAdministrativeAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateReportingAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateReportingAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserSubscription(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateSubscription', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
            .catch((error) => {
                console.log(error.response.data);
                throw Error('An error has occurred calling the web api: ' + error);
            });
    }

    static GetUserProfile(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUserProfile/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUserPermissions(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUserPermissions/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserPermissions(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateUserPermissions', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUserWorkingHours(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUserWorkingHours/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateUserWorkingHours(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateUserWorkingHours', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }


    // Groups

    static GetGroups(token) {
        return this.AuthenticatedApi.get('/UserConfig/GetGroups', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetUsersByGroupId(groupId, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetUsersByGroupId/' + groupId, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetInheritedGroups(userGUID, token) {
        return this.AuthenticatedApi.get('/UserConfig/GetInheritedGroups/' + userGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateGroupAdministrativeAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateGroupAdministrativeAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateGroupReportingAccess(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateGroupReportingAccess', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateGroupSubscription(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateGroupSubscription', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }
    

    // Call Queue
    static GetCallQueues(token) {
        return this.AuthenticatedApi.get('/UserConfig/GetCallQueues', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateCallQueue(data, token) {
        return this.AuthenticatedApi.post('/UserConfig/UpdateCallQueue', data, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    // Business Hours

    static GetTemplates(token) {
        return this.AuthenticatedApi.get('/BusinessHoursConfig/GetTemplates', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static AddTemplate(templateData, token) {
        return this.AuthenticatedApi.post('/BusinessHoursConfig/AddTemplate', templateData, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateTemplate(templateData, token) {
        return this.AuthenticatedApi.post('/BusinessHoursConfig/UpdateTemplate', templateData, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static DeleteTemplate(templateGUID, token) {
        return this.AuthenticatedApi.delete('/BusinessHoursConfig/DeleteTemplate/' + templateGUID, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    
    // Tenant Details

    static GetTenantDetails(token) {
        return this.AuthenticatedApi.get('/Tenant/', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    // Account Summary
    static GetAccountSummary(token) {
        return this.AuthenticatedApi.get('/Tenant/GetAccountSummary', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateTrialPeriod(extendperiod, token) {
        return this.AuthenticatedApi.put('/Tenant/UpdateTrialPeriod', {period : extendperiod}, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateAutoBuySubscription(settingValue, token) {
        return this.AuthenticatedApi.put('/Tenant/UpdateAutoBuySubscription/', {isAutoBuySubscription :settingValue}, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateAutoRemoveDeletedEmployees(settingValue, token) {
        return this.AuthenticatedApi.put('/Tenant/UpdateAutoRemoveDeletedEmployees/', {isAutoRemoveDeletedEmployees :settingValue}, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    // Select Permissions
    static GetPermissionsUrl(token) {
        return this.AuthenticatedApi.get('/TenantPermission/GetPermissionsUrl', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) =>
            {
                return response.data;
            })
            .catch
            ((error) =>
            {
                throw Error('An error has occurred calling the web api: '
                    + error);
                return null;
            });

    }

    static SelectPermissions(token) {
        return this.AuthenticatedApi.get('/TenantPermission/SelectPermissions', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                return response.data;
            })

            .catch
            ((error) => {
                throw Error('An error has occurred calling the web api: '
                    + error);
                return null;
            });
    }

    static GetTenantStatus(token) {
        return this.AuthenticatedApi.get('/TenantPermission/GetTenantStatus', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                return response.data;
            })

            .catch
            ((error) => {
                throw Error('An error has occurred calling the web api: ' + error);
            });
    }
    // API Token 
    static GetTokens(token) {
        return this.AuthenticatedApi.get('/APIToken/GetTokens', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static CreateNewToken(tokenData, token) {
        return this.AuthenticatedApi.post('/APIToken/CreateToken', tokenData, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static DeleteToken(tokenId, token) {
        return this.AuthenticatedApi.delete('/APIToken/DeleteToken/' + tokenId, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }


    // Dashboard Schedules
    static GetSchedules(token) {
        return this.AuthenticatedApi.get('/DashboardSchedules/GetSchedules', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static CreateSchedule(scheduleData, token) {
        return this.AuthenticatedApi.post('/DashboardSchedules/CreateSchedule', scheduleData, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static UpdateSchedule(scheduleData, token) {
        return this.AuthenticatedApi.put('/DashboardSchedules/UpdateSchedule', scheduleData, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static DeleteSchedule(scheduleId, token) {
        return this.AuthenticatedApi.delete('/DashboardSchedules/DeleteSchedule/' + scheduleId, {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

    static GetDashboardList(token) {
        return this.AuthenticatedApi.get('/DashboardSchedules/GetDashboardList', {
            headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => { return response.data; })
        .catch((error) => { throw Error('An error has occurred calling the web api: ' + error); });
    }

}

export default ConfigurationApiService;
