import React, { useEffect, useState } from 'react';
import { Button, Grid, Popup, Flex, Label, Dropdown, Text } from '@fluentui/react-northstar';
import { FilterIcon, CalendarIcon, ChevronDownIcon, ChevronUpIcon, SaveIcon, CloseIcon } from '@fluentui/react-icons-northstar';
import TeamsDatePicker from "../../Shared/uicomponents/TeamsDatePicker";
import NativeDatePicker from "../../Shared/uicomponents/NativeDatePicker";
import { Popup as KendoPopup } from '@progress/kendo-react-popup';

const FilterPopup = ({ startDate, period, setPeriod, endDate, setStartDate, setEndDate, setTimeCategory, timeCategory, fetchData, myNetworkData, selectedMyNetwork, setSelectedMyNetwork }) => {
  const periodItems = [
    { id: 1, key: 'Today', header: 'Today' },
    { id: 2, key: 'Last 7 days', header: 'Last 7 days' },
    { id: 3, key: 'Last 30 days', header: 'Last 30 days' },
    { id: 4, key: 'Last 6 months', header: 'Last 6 months' },
    { id: 5, key: 'Custom Date', header: 'Custome Date' },
  ];
  const timeItems = [
    { id: 1, key: 'Full Day', header: 'Full Day' },
    { id: 2, key: 'In working Hours', header: 'In working Hours' },
    { id: 3, key: 'In off working hours', header: 'In off working hours' },
  ];
  const anchor = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [customDate, setCustomDate] = useState(period === 5 ? true : false);
  const buttonStyles = {
    padding: '8px 12px',
    height: '38px',
    minWidth: '64px',
    marginLeft: '20px'
  };
  const context = {
    teams: {
      hostClientType: {
        web: true,
        desktop: false,
      }
    }
  }
  const handlePeriodChange = (value) => {
    setPeriod(value.id);
    setCustomDate(false);
    if (value.id === 1) {
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (value.id === 2) {
      const lastWeekStartDate = new Date();
      lastWeekStartDate.setDate(lastWeekStartDate.getDate() - 7);
      setStartDate(lastWeekStartDate);
      setEndDate(new Date());
    } else if (value.id === 3) {
      const lastMonthStartDate = new Date();
      lastMonthStartDate.setMonth(lastMonthStartDate.getMonth() - 1);
      setStartDate(lastMonthStartDate);
      setEndDate(new Date());
    } else if (value.id === 4) {
      const lastMonthStartDate = new Date();
      lastMonthStartDate.setMonth(lastMonthStartDate.getMonth() - 6);
      setStartDate(lastMonthStartDate);
      setEndDate(new Date());
    } else if (value.id === 5) {
      setCustomDate(true)
    }
  };

  const PopupContent = (
    <div style={{ padding: '20px' }}>
      <Flex column gap="gap.medium">
        <Flex gap="gap.small" vAlign="center">
          <FilterIcon size="medium" />
          <Text content="Filter Options" size="large" weight="semibold" />
        </Flex>
        <label>My Reporting Network</label>
        <Dropdown
          className='insights-dropdown-list-cst'
          items={myNetworkData}
          placeholder="Select People"
          checkable
          fluid
          value={selectedMyNetwork}
          label="My People"
          onChange={(e, { value }) => setSelectedMyNetwork(value)}
        />
        <div>
          <label>Time Period</label>
          <Dropdown
            items={periodItems}
            defaultValue={periodItems.find(periodItem => periodItem.id === period)}
            value={periodItems.find(periodItem => periodItem.id === period)}
            onChange={(e, { value }) => handlePeriodChange(value)}
            placeholder="Select Period"
            checkable
            fluid
            label="Period"
          />
        </div>
        {customDate ?
          <>
            <Flex gap="gap.small">
              <CalendarIcon size="medium" />
              <Text content="Date Range" size="large" weight="semibold" />
            </Flex>

            <div className="custom-time-container">
              <div>
                <label>Start Date</label>
                {
                  context.teams.hostClientType.web ||
                    context.teams.hostClientType.desktop ? (
                    <TeamsDatePicker
                      required={true}
                      value={startDate}
                      onChange={(value) => setStartDate(value)}
                    />
                  ) : (
                    <NativeDatePicker
                      id="startDate"
                      value={startDate}
                      onChange={(value) => setStartDate(value)}
                      required={true}
                    />
                  )
                }
              </div>
              <div>
                <label>End Date</label>
                {
                  context.teams.hostClientType.web ||
                    context.teams.hostClientType.desktop ? (
                    <TeamsDatePicker
                      required={true}
                      minDate={startDate > endDate ? endDate : startDate}
                      value={endDate}
                      onChange={(value) => setEndDate(value)}
                    />
                  ) : (
                    <NativeDatePicker
                      id="endDate"
                      minDate={startDate > endDate ? endDate : startDate}
                      value={endDate}
                      onChange={(value) => setEndDate(value)}
                      required={true}
                    />
                  )
                }
              </div>
            </div>
          </> : <></>
        }
        <div>
          <label>Time Category</label>
          <Dropdown
            items={timeItems}
            defaultValue={timeItems.find(timeItem => timeItem.id === timeCategory)}
            value={timeItems.find(timeItem => timeItem.id === timeCategory)}
            onChange={(e, { value }) => setTimeCategory(value.id)}
            placeholder="Select Time"
            checkable
            fluid
            label="Time"
          />
        </div>
        <Flex gap="gap.small" hAlign="end">
          <Button
            content="Cancel"
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            secondary
          />
          <Button
            content="Apply"
            icon={<SaveIcon />}
            onClick={() => {
              if (selectedMyNetwork === null) {
                fetchData();
              }
              else if (selectedMyNetwork.id) {
                fetchData(selectedMyNetwork.key, null);
              } else {
                fetchData(null, selectedMyNetwork.key);
              }
              setOpen(false)
            }}
            primary
          />
        </Flex>
      </Flex>
    </div>
  );

  return (
    <Grid columns="1" styles={{ justifyItems: 'center' }}>
      <Button
          ref={anchor}
          icon={<FilterIcon />}
          styles={buttonStyles}
          title="Show Filters"
          content="Filters"
          onClick={() => setOpen(!open)}
          iconPosition="after"
          />
      {/* <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        onClick={onClick}
        ref={anchor}
      >
        Filters
      </button> */}
      <KendoPopup anchor={anchor.current} 
        collision={{
          horizontal: "fit",
          vertical: "flip"
        }}
        anchorAlign={{
          horizontal: "right",
          vertical: "bottom",
        }}
        popupAlign={{
          horizontal: "right",
          vertical: "top",
        }} 
        show={open} popupClass={"insights-filter-popup"}>
        <div style={{ padding: '20px' }}>
          <Flex column gap="gap.medium">
            <Flex gap="gap.small" vAlign="center">
              <FilterIcon size="medium" />
              <Text content="Filter Options" size="large" weight="semibold" />
            </Flex>
            <label>My Reporting Network</label>
            <Dropdown
              className='insights-dropdown-list-cst'
              items={myNetworkData}
              placeholder="Select People"
              checkable
              fluid
              value={selectedMyNetwork}
              label="My People"
              onChange={(e, { value }) => setSelectedMyNetwork(value)}
            />
            <div>
              <label>Time Period</label>
              <Dropdown
                items={periodItems}
                defaultValue={periodItems.find(periodItem => periodItem.id === period)}
                value={periodItems.find(periodItem => periodItem.id === period)}
                onChange={(e, { value }) => handlePeriodChange(value)}
                placeholder="Select Period"
                checkable
                fluid
                label="Period"
              />
            </div>
            {customDate ?
              <>
                {/* <Flex gap="gap.small">
                  <CalendarIcon size="medium" />
                  <Text content="Date Range" size="large" weight="semibold" />
                </Flex> */}
                <Text className="mb-0" content="Please select the date range." />
                <div className="custom-time-container">
                  <div>
                    <label>Start Date</label>
                    {
                      context.teams.hostClientType.web ||
                        context.teams.hostClientType.desktop ? (
                        <TeamsDatePicker
                          required={true}
                          value={startDate}
                          onChange={(value) => setStartDate(value)}
                        />
                      ) : (
                        <NativeDatePicker
                          id="startDate"
                          value={startDate}
                          onChange={(value) => setStartDate(value)}
                          required={true}
                        />
                      )
                    }
                  </div>
                  <div>
                    <label>End Date</label>
                    {
                      context.teams.hostClientType.web ||
                        context.teams.hostClientType.desktop ? (
                        <TeamsDatePicker
                          required={true}
                          minDate={startDate > endDate ? endDate : startDate}
                          value={endDate}
                          onChange={(value) => setEndDate(value)}
                        />
                      ) : (
                        <NativeDatePicker
                          id="endDate"
                          minDate={startDate > endDate ? endDate : startDate}
                          value={endDate}
                          onChange={(value) => setEndDate(value)}
                          required={true}
                        />
                      )
                    }
                  </div>
                </div>
              </> : <></>
            }
            <div>
              <label>Time Category</label>
              <Dropdown
                items={timeItems}
                defaultValue={timeItems.find(timeItem => timeItem.id === timeCategory)}
                value={timeItems.find(timeItem => timeItem.id === timeCategory)}
                onChange={(e, { value }) => setTimeCategory(value.id)}
                placeholder="Select Time"
                checkable
                fluid
                label="Time"
              />
            </div>
            <Flex gap="gap.small" hAlign="end">
              <Button
                content="Cancel"
                icon={<CloseIcon />}
                onClick={() => setOpen(false)}
                secondary
              />
              <Button
                content="Apply"
                icon={<SaveIcon />}
                onClick={() => {
                  if (selectedMyNetwork === null) {
                    fetchData();
                  }
                  else if (selectedMyNetwork.id) {
                    fetchData(selectedMyNetwork.key, null);
                  } else {
                    fetchData(null, selectedMyNetwork.key);
                  }
                  setOpen(false)
                }}
                primary
              />
            </Flex>
          </Flex>
        </div>
      </KendoPopup>
      {/* <Popup
        open={open}
        align="end"
        position="below"
        trigger={
          <Button
            icon={<FilterIcon />}
            styles={buttonStyles}
            title="Show Filters"
            content="Filters"
            onClick={() => setOpen(!open)}
            iconPosition="after"
          />
        }
        content={{
          content: PopupContent
        }}
      /> */}
    </Grid>
  );
};

export default FilterPopup;