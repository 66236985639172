import * as React from 'react';
import './multiSegmentRangeSlider.scss';
import { Loader, Text } from '@fluentui/react-northstar';
// Function to get different colors for each section
const getMultiSegmentRangeSliderColor = (colors, index) => {
    return colors[index % colors.length];
};

const MultiSegmentRangeSlider = (props) => {
    // const colors = ['linear-gradient(90deg, #FC466B 0%, #fb9a3f 50%)', 'linear-gradient(90deg, #fb9a3f 0%, #FFD700 50%, #d4ff00 100%)', 'linear-gradient(90deg, #d4ff00 0%, #00ff88 50%, #d4ff00 100%)', 'linear-gradient(90deg, #d4ff00 0%, #FFD700 50%, #fb9a3f 100%)', 'linear-gradient(90deg, #fb9a3f 0%, #FC466B 50%)'];
    //const colors = ['linear-gradient(90deg, #dc3545 0%, #ff7e07 50%)', 'linear-gradient(90deg, #ff7e07 0%, #ffc107 50%, #a3a728 100%)', 'linear-gradient(90deg, #a3a728 0%, #28a745 50%, #a3a728 100%)', 'linear-gradient(90deg, #a3a728 0%, #ffc107 50%, #ff7e07 100%)', 'linear-gradient(90deg, #ff7e07 0%, #dc3545 50%)'];

    const colors = ['#dc3545', '#ffc107', '#28a745', '#ffc107', '#dc3545']
    const gzPercent = props.defaultGreenZonePercentage || 20; // Green Zone Percentage
    const yzPercent = props.defaultYellowZonePercentage || 40; // Yellow Zone Percentage
    const [sliderValue, setSliderValue] = React.useState();
    const [marks, setMarks] = React.useState();
    const [inputSliderFocusedClassName, setInputSliderFocusedClassName] = React.useState('');
    const [draggingIndex, setDraggingIndex] = React.useState(null);
    const sliderRef = React.useRef(null);

    React.useEffect(() => {
        setSliderValue(props.setValue);
        if (props.setZoneBars != null && props.setZoneBars[1] <= props.setValue && props.setValue <= props.setZoneBars[2]) {
            setMarks(props.setZoneBars);
        }
        else {
            if (props.onSliderMarksChange) {
                props.onSliderMarksChange(props.fieldName, calculateGradientZones(props.setValue, gzPercent, yzPercent))
            }
        }
    }, [props.setValue])

    React.useEffect(() => {
        if (props.setZoneBars != null && props.setZoneBars[1] <= props.setValue && props.setValue <= props.setZoneBars[2]) {
            setMarks(props.setZoneBars);
        }
        else {
            setMarks(calculateGradientZones(props.setValue, gzPercent, yzPercent));
        }
    }, [props.setZoneBars])

    const updateMarks = (newPosition) => {
        newPosition = Math.round(newPosition);
        let updatedMarks = [...marks];
        if (draggingIndex === -1) {
            newPosition = Math.max(props.min, Math.min(props.max, newPosition));
            setInputSliderFocusedClassName('focused');
            if (props.onSliderInputChange) {
                props.onSliderInputChange(props.fieldName, draggingIndex === -1 ? newPosition : sliderValue);
            }
            if (props.onSliderMarksChange) {
                if (props.setZoneBars != null && props.setZoneBars[1] <= newPosition && props.setZoneBars[2] >= newPosition) {

                }
                else {
                    updatedMarks = calculateGradientZones(newPosition, gzPercent, yzPercent);
                    props.onSliderMarksChange(props.fieldName, updatedMarks);
                }
            }
        } else {
            setInputSliderFocusedClassName('');
            if (draggingIndex > 0) {
                if (draggingIndex === 1) {
                    updatedMarks[draggingIndex] = Math.max(
                        updatedMarks[draggingIndex - 1],
                        newPosition
                    );
                    updatedMarks[draggingIndex] = Math.min(
                        sliderValue,
                        updatedMarks[draggingIndex]
                    );
                } else if (draggingIndex === 2) {
                    updatedMarks[draggingIndex] = Math.max(
                        updatedMarks[draggingIndex - 1],
                        newPosition,
                        sliderValue
                    );
                } else {
                    updatedMarks[draggingIndex] = Math.max(
                        updatedMarks[draggingIndex - 1],
                        newPosition
                    );
                }
            } else {
                updatedMarks[draggingIndex] = newPosition;
            }

            if (draggingIndex < marks.length - 1) {
                updatedMarks[draggingIndex] = Math.min(
                    updatedMarks[draggingIndex],
                    marks[draggingIndex + 1]
                );
            } else {
                updatedMarks[draggingIndex] = Math.min(
                    updatedMarks[draggingIndex],
                    100
                );
            }

            if (props.onSliderMarksChange) {
                props.onSliderMarksChange(props.fieldName, updatedMarks);
            }
        }
    };

    const handleMouseMove = React.useCallback(
        (event) => {
            if (draggingIndex !== null && sliderRef.current) {
                const sliderRect = sliderRef.current.getBoundingClientRect();
                const newPosition = Math.min(
                    Math.max(
                        ((event.clientX - sliderRect.left) / sliderRect.width) * 100,
                        0
                    ),
                    100
                );
                updateMarks(newPosition);
            }
        },
        [draggingIndex, marks]
    );

    const handleTouchMove = React.useCallback(
        (event) => {
            if (draggingIndex !== null && sliderRef.current) {
                const touch = event.touches[0];
                const sliderRect = sliderRef.current.getBoundingClientRect();
                const newPosition = Math.min(
                    Math.max(
                        ((touch.clientX - sliderRect.left) / sliderRect.width) * 100,
                        0
                    ),
                    100
                );
                updateMarks(newPosition);
            }
        },
        [draggingIndex, marks]
    );

    const handleMouseUp = React.useCallback(() => {
        setDraggingIndex(null);
        setInputSliderFocusedClassName('');
    }, []);

    const handleTouchEnd = React.useCallback(() => {
        setDraggingIndex(null);
        setInputSliderFocusedClassName('');
    }, []);

    React.useEffect(() => {
        if (props.draggable && !props.readonly) {
            if (draggingIndex !== null) {
                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
                document.addEventListener('touchmove', handleTouchMove);
                document.addEventListener('touchend', handleTouchEnd);
            } else {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
                document.removeEventListener('touchmove', handleTouchMove);
                document.removeEventListener('touchend', handleTouchEnd);
            }

            return () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
                document.removeEventListener('touchmove', handleTouchMove);
                document.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, [
        draggingIndex,
        handleMouseMove,
        handleMouseUp,
        handleTouchMove,
        handleTouchEnd,
    ]);

    function calculateGradientZones(cv, gzPercent, yzPercent) {
        // Calculate the actual widths of the zones based on the central value percentage
        const gz = Math.round((gzPercent / 100) * cv);
        const yz = Math.round((yzPercent / 100) * cv);
        // Calculate positions based on the central value and zone widths
        const greenStart = Math.max(0, cv - gz);
        const greenEnd = Math.min(100, Number(cv) + Number(gz));
        const yellowStartBeforeGreen = Math.max(0, cv - yz);
        const yellowEndBeforeGreen = Math.max(0, cv - gz);
        const yellowStartAfterGreen = Math.min(100, Number(cv) + Number(gz));
        const yellowEndAfterGreen = Math.min(100, Number(cv) + Number(yz));
        return [
            Math.round(yellowStartBeforeGreen),
            Math.round(greenStart),
            Math.round(greenEnd),
            Math.round(yellowEndAfterGreen),
        ];
    }

    return (
        marks != null ?
            <div style={{ display: `${props.show === false ? 'none' : 'block'}` }} className={`multi-segment-slider ${props.compact ? 'compact' : ''}  ${props.viewOnly ? 'view-only' : ''}`} >
                {props.headerText != null ? <Text className='slider-header'>{props.headerText}</Text> : <></>}
                <div className={`zone-slider-container  ${props.readonly ? 'read-only' : ''} ${draggingIndex != null ? 'focused' : ''}`} ref={sliderRef} key={props.fieldName}>
                    {marks.map((mark, index) => (
                        <div
                            key={index}
                            className="slider-section"
                            style={{
                                width: index === 0 ? `${mark}%` : `${mark - marks[index - 1]}%`,
                                background: getMultiSegmentRangeSliderColor(colors, index),
                                transition: 'width 0.2s ease',
                            }}
                        />
                    ))}
                    <div
                        className="slider-section"
                        style={{
                            width: `${100 - marks[marks.length - 1]}%`,
                            background: getMultiSegmentRangeSliderColor(colors, marks.length),
                            transition: 'width 0.2s ease',
                        }}
                    />
                    {marks.map((mark, index) => (
                        <div
                            key={index}
                            className="slider-mark"
                            style={{ left: `${mark}%`, transition: 'left 0.2s ease' }}
                            onMouseDown={() => (props.draggable && !props.readonly) && setDraggingIndex(index)}
                            onTouchStart={() => (props.draggable && !props.readonly) && setDraggingIndex(index)}
                        >
                            <div
                                className={`slider-mark-container  ${props.readonly ? 'read-only' : ''} ${draggingIndex == index ? 'focused' : ''
                                    }`}
                            >
                                <div className="slider-mark-label">{mark}</div>
                                <span className="slider-mark-handle"></span>
                                <span className="triangle-container"></span>
                            </div>
                        </div>
                    ))}
                    <div
                        key={-1}
                        style={{ zIndex: '2', left: `${sliderValue}%`, transition: 'left 0.2s ease' }}
                        className={`slider-input ${inputSliderFocusedClassName} ${props.readonly ? 'read-only' : ''}`}
                        onMouseDown={() => (props.draggable && !props.readonly) && setDraggingIndex(-1)}
                        onTouchStart={() => (props.draggable && !props.readonly) && setDraggingIndex(-1)}
                    >
                        <span className="drag-indicator"><span></span></span>
                        <span className='slider-value'>{sliderValue}</span>
                    </div>
                </div>
                {props.displayValue != null ? <Text className='slider-display-value'>{Math.round(props.displayValue)}%</Text> : <></>}
            </div>
            : <Loader />
    );
};

export default MultiSegmentRangeSlider;